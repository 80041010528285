import React, { useEffect, useState } from 'react'
import {
  Button,
  Row,
  Table,
  Modal,
  message,
  Avatar,
  notification,
  Input
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import fetchHousekeeperinRoom from '../../api/room/getHousekeeperinRoom'
import getAllHouseKeeper from '../../api/housekeeper/getAllHouseKeeper'
import editHousekeeperinroom from '../../api/room/editHousekeeperinroom'

import Nodata from '.././nodata'

const phoneFormat = input => {
  if (!input || isNaN(input)) return `input must be a number was sent ${input}`
  if (typeof input !== 'string') input = input.toString()
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  } else if (input.length < 10) {
    return 'was not supplied enough numbers please pass a 10 digit number'
  } else if (input.length > 10) {
    return 'was supplied too many numbers please pass a 10 digit number'
  } else {
    return 'something went wrong'
  }
}
export default function AddHouseKeeper (props) {
  const { t, i18n } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [isFetch, setIsfetch] = useState(false)
  const [editData, setEditData] = useState({})
  const [housekeeperData, setHousekeeperData] = useState([])
  const [housekeeperDatafilter, setHousekeeperDatafilter] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [totalPage, setTotalfrom] = useState('')
  const [pageFisrt, setPageFisrt] = useState(1)
  const [pageLast, setPageLast] = useState(1)
  const columns = [
    {
      title: t('headdertable.housekeeper.image'),
      dataIndex: 'image',
      key: 'image',
      width: '5%',
      render: text => (
        <Avatar
          size='large'
          src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${text}`}
        />
      )
    },
    {
      title: t('headdertable.housekeeper.name'),
      dataIndex: `${i18n.language}_name`,
      key: `${i18n.language}_name`,
      render: text => text
    },
    {
      title: t('headdertable.housekeeper.username'),
      key: 'username',
      dataIndex: 'username',
      // width: '10%',
      render: text => text
    },
    {
      title: t('headdertable.housekeeper.role'),
      key: 'role',
      dataIndex: 'role',
      // sorter: (a, b) => a.role - b.role,
      render (text, record) {
        return text[`${i18n.language}_name`]
      }
    },
    {
      title: t('headdertable.housekeeper.phone'),
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      render: text => phoneFormat(text)
    }
  ]
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys)
    },
    getCheckboxProps: record => ({
      disabled: record.disable,
      // Column configuration not to be checked
      name: record.name
    })
  }

  useEffect(() => {
    if (!_.isEmpty(props.meetingroom) && !isFetch) {
      setModalOpen(props.modalOpen)
      setData()
    }
  }, [props])
  const setData = async () => {
    let room_id = props.meetingroom.id
    var housekeeper = []
    getAllHouseKeeper(async (err, res) => {
      if (err) {
        message.error('Bad Gateway: ' + err)
      }
      if (res && res.statusCode === 200) {
        res = res.data
        housekeeper = res.filter(d => d.role.id !== 1 && d.role.id !== 5 )
        if (housekeeper.length > 10) {
          setTotalfrom('1-10')
        } else {
          setTotalfrom('1-' + housekeeper.length)
        }

        const dataHousekeeperinRoom = await fetchHousekeeperinRoom(
          room_id,
          null
        )
        if (dataHousekeeperinRoom.statusCode == 200) {
          setHousekeeperData(housekeeper)
          setHousekeeperDatafilter(housekeeper)
          setSelectedKeys(
            dataHousekeeperinRoom.data.Housekeepers.map(h => h.id)
          )
          setEditData(props.meetingroom)
          setIsfetch(true)
        } else {
          notification['error']({
            message:
              dataHousekeeperinRoom.error.statusCode ||
              dataHousekeeperinRoom.status,
            description: dataHousekeeperinRoom.message
          })
        }
      }
    })
  }
  return (
    <Modal
      title={`${t('form.add_housekeeper_room')} : ${
        props.meetingroom.displayName
      }`}
      visible={modalOpen}
      onCancel={() => {
        setModalOpen(false)
        props.onClose()
        setIsfetch(false)
        setSelectedKeys([])
      }}
      closable={true}
      footer={null}
      width={1400}
    >
      <div>
        {!isFetch && <Nodata message={'bad gatway 500'} />}
        {isFetch && (
          <>
            <div style={{ float: 'right' }}>
              {totalPage} {`${t('form.from')} `}
              {housekeeperData.length}
              <Input
                suffix={
                  <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                }
                className='ant-inputsearch search-border'
                placeholder={`${t('button.search')}${t('menu.housekeeper')}`}
                style={{
                  margin: '0px 0px 10px 10px',
                  borderRadius: '8px',
                  border: '1px solid var(--ant-primary-color)',
                  maxWidth: '200px'
                }}
                onChange={e => {
                  const currValue = e.target.value
                  var dataformat = housekeeperDatafilter.map((e) => {
                    return {
                      ...e,
                      phoneNumber_name: e.phoneNumber,
                      role_th_name: e.role.th_name,
                      role_en_name: e.role.en_name,
                    }
                  })
                 
                  const filteredData = dataformat.filter(entry =>
                    Object.keys(entry).filter((k) => k.includes("name")).some(k => {
                      return String(entry[k])
                        .toLowerCase()
                        .includes(currValue.toLowerCase())
                    })
                  )
                  if (filteredData.length > 10) {
                    if (pageFisrt == 1) {
                      setTotalfrom(1 + '-' + 10)
                    } else {
                      setTotalfrom(
                        `${10 * (pageFisrt - 1) + 1} - ${
                          10 * pageFisrt > filteredData.length
                            ? filteredData.length
                            : 10 * pageFisrt
                        }`
                      )
                    }
                  } else if (filteredData.length < 10 && filteredData.length !== 0) {
                    setTotalfrom(1 + '-' + filteredData.length)
                  } else {
                    setTotalfrom('')
                  }
                  setHousekeeperData(filteredData)
                }}
              />
            </div>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              columns={columns}
              dataSource={housekeeperData.map((e, i) => {
                return { ...e, key: e.id }
              })}
              pagination={{
                onChange: (page, pageSize) => {
                  setTotalfrom(
                    `${pageSize * (page - 1) + 1} - ${
                      pageSize * page > housekeeperData.length
                        ? housekeeperData.length
                        : pageSize * page
                    }`
                  )
                  setPageFisrt(pageSize * (page - 1) + 1)
                  setPageLast(
                    pageSize * page > housekeeperData.length
                      ? housekeeperData.length
                      : pageSize * page
                  )
                },
                defaultPageSize: 10,
                showSizeChanger: false,
                pageSizeOptions: ['10']
              }}
            />
          </>
        )}
      </div>

      <Row
        style={{
          justifyContent: 'end',
          padding: '0',
          margin: '20px 0 0 0'
        }}
      >
        <Button
          htmlType='button'
          ghost
          style={{
            boxShadow: "unset",
            textShadow: "unset",
            borderRadius: '8px',
            fontSize: '14px',
            margin: '0 0 0px 10px',
            background: '#BDBDBD',
            border: '1px solid #BDBDBD',
            color: '#FFFFFF',
            fontWeight: '500'
          }}
          onClick={() => {
            setModalOpen(false)
            props.onClose()
            setIsfetch(false)
            setSelectedKeys([])
          }}
        >
          {t('button.cancel')}
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          style={{
            boxShadow: "unset",
            textShadow: "unset",
            borderRadius: '8px',
            fontSize: '14px',
            margin: '0 0 0px 10px',
            fontWeight: '500'
          }}
          onClick={() => {
            editHousekeeperinroom(
              editData.id,
              { houseKeepers: selectedKeys },
              (err, result) => {
                if (err) {
                  notification['error']({
                    message: 'User error',
                    description: err
                  })
                }

                if (
                  !err &&
                  result.statusCode >= 200 &&
                  result.statusCode < 300
                ) {
                  message.success('This is a success add housekeeper in room')
                  result = result.data
                  props.handleUpdated(result)
                  setModalOpen(false)
                  setSelectedKeys([])
                  setIsfetch(false)
                } else {
                  if (result?.status == 'error' || result?.status == 'fail') {
                    notification['error']({
                      message: result.error.statusCode || result.status,
                      description: result.message
                    })
                  }
                }
              }
            )
          }}
        >
          {t('button.add')}
        </Button>
      </Row>
    </Modal>
  )
}
