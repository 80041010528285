import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Transfer, Modal } from 'antd'
import _ from 'lodash'
function Fortest (props) {
  const [modalGroupOpen, setModalGroupOpen] = useState(true)
  const [itemData, setItemData] = useState([])
  const [targetKeys, setTargetKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  //   useEffect(() => {
  //     setModalGroupOpen(props.modelOpen)
  //     // setOptionsarea(props.optionsArea); get room redux
  //     // setItemData(props.itemData);
  //   }, [props])
  return (
    <Modal
      title='Add HouseKeeper in Meetingroom'
      visible={modalGroupOpen}
      onCancel={() => {
        setModalGroupOpen(false)
        props.onClose()
        setSelectedKeys([])
        setTargetKeys([])
      }}
      closable={true}
      footer={null}
    >
      <Transfer
        dataSource={itemData} // default data
        showSearch
        selectedKeys={selectedKeys}
        targetKeys={targetKeys}
        onChange={nextTargetKeys => {
          setTargetKeys(nextTargetKeys)
        }}
        onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
          setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
        }}
        render={item => item.title}
        style={{ width: '100%', justifyContent: 'center' }}
      />

      <Row
        style={{
          justifyContent: 'end',
          padding: '0',
          margin: '20px 0 0 0'
        }}
      >
        <Button
          htmlType='button'
          ghost
          style={{
            boxShadow: "unset",
            textShadow: "unset",
            borderRadius: '8px',
            fontSize: '14px',
            margin: '0 0 0px 10px',
            background: '#BDBDBD',
            border: '1px solid #BDBDBD',
            color: '#FFFFFF',
            fontWeight: '500'
          }}
          onClick={() => {
            setModalGroupOpen(false)
            props.onClose()
            setSelectedKeys([])
            setTargetKeys([])
          }}
        >
          Cancel
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          style={{
            boxShadow: "unset",
            textShadow: "unset",
            borderRadius: '8px',
            fontSize: '14px',
            margin: '0 0 0px 10px',
            fontWeight: '500'
          }}
        >
          Add
        </Button>
      </Row>
    </Modal>
  )
}

export default Fortest
