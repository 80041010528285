import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Modal,
    message,
    notification,
    Space
} from "antd";

import { useTranslation } from "react-i18next";

import resetPassword from "../../api/housekeeper/resetpasswordHouseKeeper";

export default function ResetpasswordHouseKeeper(props) {
    const { t, i18n } = useTranslation()
    const [modalPasswordOpen, setModalPasswordOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const [form] = Form.useForm();
    useEffect(() => {
        setUserData(props.housekeeper);
        setModalPasswordOpen(props.modalOpen);
    }, [props]);
    return (
        <Modal
            title={`${t('headers.resetpassword')} : ` + userData[`${i18n.language}_name`]}
            visible={modalPasswordOpen}
            onCancel={() => {
                setModalPasswordOpen(false);
                props.onClose();
                form.resetFields()
            }}
            closable={true}
            form={form}
            footer={null}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={(e) => {
                    if (e.passwordConf === e.password) {
                        resetPassword(userData.id, e, (err, res) => {
                            if (!err && res.statusCode === 200) {
                                message.success(`Reset Password successfully.`);
                                setModalPasswordOpen(false);
                                props.onClose();
                                props.handleUpdated();
                            } else {
                                notification["error"]({
                                    message: res.error.statusCode || res.status,
                                    description: res.message,
                                });
                            }
                        });
                    }else{
                        message.warning(`Password notmath.`);
                    }
                }}
            >
                <Row>
                    <Col span={12} style={{ padding: "0 10px" }}>
                        <Form.Item
                            label={
                                <div style={{ color: "#4F4F4F", fontWeight: "500" }}>
                                    {t('form.newpassword')}
                                </div>
                            }
                            name="password"
                            rules={[
                                { required: true, message: `${t("message.inputnewpassword")}`,},
                                { min: 8, message: `${t("message.newpasswordcharacters")}`,},
                            ]}
                        >
                            <Input.Password
                                placeholder={t('form.newpassword')}
                                showCount
                                maxLength={25}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0 10px" }}>
                        <Form.Item
                            label={
                                <div style={{ color: "#4F4F4F", fontWeight: "500" }}>
                                    {t('form.confirmnewpassword')}
                                </div>
                            }
                            name="passwordConf"
                            rules={[
                                { required: true, message: `${t('message.inputconfirmpassword')}`},
                                { min: 8, message: `${t('message.confirmpasswordcharacters')}`},
                            ]}
                        >
                            <Input.Password
                                placeholder={t('form.confirmnewpassword')}
                                showCount
                                maxLength={25}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Space>
                        <Button
                            htmlType="button"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClick={() => {
                                setModalPasswordOpen(false);
                                props.onClose();
                                form.resetFields()
                            }}
                        >
                            {t("button.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            style={{ borderRadius: "8px", fontWeight: "500",  boxShadow: "unset",
                            textShadow: "unset", }}
                            htmlType="submit"
                        >
                            {t("button.submit")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}
