import React, { useState, useEffect } from "react";
import { Button, Avatar, Row } from "antd";
import Modalaccount from "./modalAccount";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from 'react-i18next'
import Nodata from "./nodata";
import { useSelector } from "react-redux";
import { getMydata } from "../slice/roomSlice";

export default function Accountprofile(props) {
  const { t } = useTranslation()
  const dataMyprofile = useSelector(getMydata);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFetch, setIsfetch] = useState(false);
  const [userData, setUserdata] = useState({});
  var navigate = useNavigate();
  useEffect(() => {
    if (!_.isEmpty(dataMyprofile)) {
      setUserdata(dataMyprofile);
      setIsfetch(true);
    }
  }, [dataMyprofile]);

  return (
    <div
      style={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 8px rgba(97, 97, 97, 0.2)",
        borderRadius: "0px 0px 12px 12px",
        width: 250,
        marginTop:'20px',        
      }}
    >
      {!isFetch && <Nodata message="bad gateway" />}
      {isFetch && (
        <>
          <Avatar
            size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 80,
              xxl: 100,
            }}
            src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${userData.image}`}
            style={{
              marginTop: "40px",
            }}
          />
          <Row
            style={{
              marginTop: "20px",
              fontSize: "18px",
              color: "rgba(79, 79, 79, 0.75)",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            {userData.username}
          </Row>
          <Row
            style={{
              marginBottom: "20px",
              fontSize: "16px",
              // fontWeight: "400",
              color: "rgba(79, 79, 79, 0.75)",
            }}
          >
            {userData.email}
          </Row>
          <Button
            type="primary"
            ghost
            style={{
              borderRadius: "13px",
              marginBottom: "13px",
              boxShadow: "unset",
              textShadow: "unset",
            }}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {t("headers.manageaccount")}
          </Button>
          <Row
            justify="center"
            align="middle"
            style={{
              background: "#BDBDBD",
              width: "100%",
              cursor: "pointer",
              color: "rgba(79, 79, 79, 0.75)",
              borderRadius: "0px 0px 12px 12px",
              padding: "12px 0",
              // height: "30px",
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => {
              console.log("logout");
              localStorage.removeItem("token");
              localStorage.removeItem("profile");
              navigate("/login");
            }}
          >
            {t("button.logout")}
          </Row>
          <Modalaccount
            modalOpen={isModalVisible}
            onClose={() => {
              setIsModalVisible(false);
            }}
          />
        </>
      )}
    </div>
  );
}
