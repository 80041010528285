const CreateGroupItem = (data, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  fetch(
    `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/group/item`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )
    .then(res => res.json())
    .then(json => cb(null, json))
    .catch(err => cb(err, null))
}

export default CreateGroupItem
