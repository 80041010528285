import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Layout from './container/layoutMain/main'
import Overview from './pages/overview'
import GroupItem from './pages/groupitem'
import HouseKeeper from './pages/housekeeper'
import Item from './pages/item'
import Meetingroom from './pages/meetingroom'
import Board from './pages/board'
import Order from './pages/order'
import Login from './pages/login'
import History from './pages/history'
import Fortest from './components/fortest'
import { ConfigProvider } from 'antd'
import useToken from './utils/useToken'
import Notfound from './pages/status/404'
import _ from 'lodash'
import fetchApi from './api/root/fetchApi'
const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  return isAuthenticated ? children : <Navigate to='/login' />
}

function Root() {
  const { token, setToken } = useToken()
  const [isAuthenticated, setIsauthenticated] = useState(token ? true : false)

  var navigate = useNavigate()
  useEffect(() => {
    if (!isAuthenticated && token) {
      fetchApi(token, (err, response) => {
        if (!err && !_.isEmpty(response)) {
          setIsauthenticated(true)
          navigate('/overview')
        } else {
          setIsauthenticated(false)
        }
      })
    }
  }, [token])
  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: '#FFC557'
      }
    })
  }, [])
  return (
    <ConfigProvider prefixCls='ant'>
      <Routes>
        <Route path='/' element={<Navigate to='/overview' />} />
        <Route
          path='/'
          element={
            <PrivateRoute auth={{ isAuthenticated: isAuthenticated }}>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index path='overview' element={<Overview />} />
          <Route path='groupitem' element={<GroupItem />} />
          <Route path='housekeeper' element={<HouseKeeper />} />
          <Route path='item' element={<Item />} />
          <Route path='meetingroom' element={<Meetingroom />} />
          <Route path='order' element={<Order />} />
          <Route path='board' element={<Board />} />
          <Route path='history' element={<History />} />
        </Route>
        <Route path='login' element={<Login setToken={setToken} />} />
        <Route path='demo' element={<Fortest />} />
        <Route path='*' element={<Notfound isAuth={isAuthenticated} />} />
      </Routes>
    </ConfigProvider>
  )
}
export default Root
