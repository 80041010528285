import dataRole from '../role.json'
const CreateUser = (formdata, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  let formData = new FormData()
  formData.append('username', formdata.username)
  formData.append('password', formdata.password)
  formData.append('th_name', formdata.th_name)
  formData.append('th_surname', formdata.th_surname)
  formData.append('en_name', formdata.en_name)
  formData.append('en_surname', formdata.en_surname)
  formData.append('email', formdata.email)
  formData.append( 'role', dataRole.data.find(dr => dr.value === formdata.role).label?.toLowerCase() )
  formData.append('phoneNumber', formdata.phoneNumber)
  formData.append('image', formdata.image)
  
  fetch(`${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/maids`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.token}`,
      // 'Content-Type': 'multipart/form-data'
    },
    body: formData
  })
    .then(res => res.json())
    .then(json => cb(null, json))
    .catch(err => cb(err, null))
}

export default CreateUser
