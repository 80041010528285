const fetchItem =  cb => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  fetch(`${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/items`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token.token}`,
      Accept: 'application/json'
    })
  })
    .then(res => res.json())
    .then(json => {
      const tmpData = {
        statusCode: json.statusCode,
        data: json.data.map((e, i) => {
          return {
            key: i + 1,
            th_name: e.th_name,
            en_name: e.en_name,
            id: e.id,
            image: e.image,
            enabledisplay: e.enabledisplay
          }
        })
      }
      return cb(null, tmpData)
    })
    .catch(err => cb(err, null))
}

export default fetchItem
