const CreateItem = (formdata, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    let formData = new FormData()
    formData.append('th_name', formdata.th_name)
    formData.append('en_name', formdata.en_name)
    formData.append('itemtypeName', 'common')
    formData.append('image', formdata.image)
    fetch(`${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/items`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.token}`,
        // 'Content-Type': 'multipart/form-data'
      },
      body: formData
    })
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
  }
  
  export default CreateItem
  