import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'antd'

import { useTranslation } from 'react-i18next'

import moment from 'moment'

import _ from 'lodash'
export default function DetailOrder (props) {
  const { t, i18n } = useTranslation()
  const [Room, setRoom] = useState('')
  const [Order, setOrder] = useState('')
  const [Items, setItems] = useState([])
  const [total, setTotal] = useState('')
  useEffect(() => {
    if (!_.isEmpty(props.order)) {
      setRoom(props.order.Room.name)
      setOrder({ id: props.order.id, createdAt: props.order.createdAt })
      setItems(props.order.Orderlists)
      let data = props.order.Orderlists
      let tmp = data
        .map(d => d.piece)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
      setTotal(tmp)
    }
  }, [props])
  return (
    <div style={{ padding: '12px 14px', borderRadius: '8px' }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <div style={{ color: '#C4C4C4', fontSize: '16px' }}>{Room}</div>
        <div style={{ color: '#C4C4C4', fontSize: '16px' }}>
          {moment(Order.createdAt).format('HH:mm')}
        </div>
      </Row>
      <div style={{ color: '#C4C4C4', fontSize: '16px' }}>
        {t('headers.overview.ordernumber')} : <span>{Order.id}</span>
      </div>
      <hr />
      <div
        style={{ minHeight: '40px', overflowX: 'hidden', margin: '17px 0px' }}
      >
        {Items.map((pd, index) => {
          return (
            <>
              <Row
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '14px 0px 14px 0px'
                }}
                key={index + '_pd'}
              >
                <Col
                  xs={17}
                  sm={17}
                  md={17}
                  lg={17}
                  xl={14}
                  xxl={14}
                  style={{ textAlign: 'start' }}
                >
                  <Row>
                    <Col xs={10} sm={10} md={10} lg={6} xl={5} xxl={4}>
                      <img
                        src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${pd.Item.image}`}
                        alt=''
                        style={{
                          padding: '0',
                          width: '25px',
                          height: '25px'
                        }}
                      />
                    </Col>
                    <Col
                      xs={14}
                      sm={14}
                      md={14}
                      lg={18}
                      xl={19}
                      xxl={20}
                      style={{ alignSelf: 'center' }}
                    >
                      <div
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          color: '#8C8C8C',
                          padding: '0 12px 0 12px'
                        }}
                      >
                        {pd.Item[`${i18n.language}_name`]}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={15}
                  sm={15}
                  md={10}
                  lg={7}
                  xl={8}
                  xxl={4}
                  style={{ textAlign: 'end' }}
                >
                  <Form.Item style={{ margin: '0' }}>
                    <div
                      style={{
                        fontWeight: '400',
                        fontSize: '16px',
                        color: '#8C8C8C'
                      }}
                    >
                      {pd.piece}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        })}
      </div>
      <hr />
      <Row style={{ justifyContent: 'space-between' }}>
        <div style={{ color: '#8C8C8C', fontSize: '16px' }}>
          {t('headers.overview.totalorder')}
        </div>
        <div style={{ color: '#8C8C8C', fontSize: '16px' }}>{total}</div>
      </Row>
      <hr style={{ marginBottom: '17px' }} />
    </div>
  )
}
