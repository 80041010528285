const fetchUserone = (id, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  fetch(
    `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/maids/${id}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token.token}`,
        Accept: 'application/json'
      })
    }
  )
    .then(res => res.json())
    .then(json => {
      const tmpData = {
        statusCode: json.statusCode,
        data: {
          key: 0,
          th_name: json.data.th_name,
          th_surname: json.data.th_surname,
          en_name: json.data.en_name,
          en_surname: json.data.en_surname,
          email: json.data.email,
          id: json.data.id,
          image: json.data.image,
          username: json.data.username,
          phoneNumber: json.data.phoneNumber,
          role: json.data.HousekeeperRole,
          available: json.data.available
        }
      }
      return cb(null, tmpData)
    })
    .catch(err => cb(err, null))
}

export default fetchUserone
