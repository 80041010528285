import mqtt from 'mqtt/dist/mqtt';

const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)
var options = {
    username: `${process.env.REACT_APP_MQTT_USERNAME}`,
    password: `${process.env.REACT_APP_MQTT_PASSWORD}`,
    keepalive: 30,
    clientId: clientId,
    protocolId: 'MQTT',
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
};

const getMQTTClient = (cb) => {
    options.clientId = clientId;
    var client = mqtt.connect(`ws://${process.env.REACT_APP_MQTT_URL}:${process.env.REACT_APP_MQTT_PORT}`, options);
    // client.on('connect', function () {
    //     console.log('client connected:' + clientId)
    //     // client.subscribe('/aa/#', { qos: 0 })
    //     // client.publish('topic', 'wss secure connection demo...!', { qos: 0, retain: false })
    // })
    // console.log(options);

    return client

}
const Subscribe = (client, topic) => {
    // console.log("topic", topic);
    client.subscribe(topic, { qos: 0 })
}
export {
    getMQTTClient,
    Subscribe
}

// client.on('error', function (err) {
//     console.log("err",err)
//     client.end()
// })


// client.on('message', function (topic, message, packet) {
//     console.log('Received Message:= ' + message.toString() + '\nOn topic:= ' + topic)
// })

// client.on('close', function () {
//     console.log(clientId + ' disconnected')
// })
