import React, { useEffect, useState } from 'react'
import {
  Table,
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  message,
  notification
} from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { getMeetingroom } from '../../slice/roomSlice'

import fetchBoardone from '../../api/board/getOneBoard'
import editBoard from '../../api/board/editBoard'

import Nodata from '.././nodata'

export default function ModaleditBoard(prop) {
  const Meetingroom = useSelector(getMeetingroom)

  const [isFetch, setIsfetch] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [editData, setEditData] = useState({})
  const [roomData, setRoomdata] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [form] = Form.useForm()
  const { t, i18n } = useTranslation()
  const nameBoard = `${t('menu.board')}`
  const [countPage, setCount] = useState('1-10')
  const columns = [
    {
      title: t('headdertable.meetingroom.name'),
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: t('headdertable.meetingroom.project'),
      key: 'label',
      dataIndex: 'label',
      width: '15%',
      render: text => text
    },
    {
      title: t('headdertable.meetingroom.building'),
      key: 'building',
      dataIndex: 'building',
      width: '10%',
      render: text => text
    },
    {
      title: t('headdertable.meetingroom.floor'),
      dataIndex: `floorNumber`,
      key: `floorNumber`,
      width: '5%',
      sorter: (a, b) => a.floorNumber - b.floorNumber,
      render: text => text
    },
    {
      title: t('headdertable.meetingroom.zone'),
      dataIndex: `floorLabel`,
      key: `floorLabel`,
      width: '5%',
      render: text => text
    }
  ]
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length <= 16) {
        setSelectedKeys(selectedRowKeys)
      }
    },
    getCheckboxProps: record => ({
      disabled: record.disable,
      // Column configuration not to be checked
      name: record.name
    })
  }
  useEffect(() => {
    setModalEditOpen(prop.modalOpen)
  }, [prop])
  useEffect(() => {
    if (prop.board?.th_name == 'none' && !isFetch) {
      form.resetFields()
      let room_id = prop.board.id
      fetchBoardone(room_id, (err, res) => {
        if (res && res.statusCode === 200) {
          res = res.data
          let data = res
          form.setFieldsValue({
            id: data.id,
            th_name: data.th_name,
            en_name: data.en_name,
            username: data.AccountBoard?.username || '',
            descriptions: data.description
          })
          setEditData(data)
          setSelectedKeys(data.Rooms.map(r => r.id))
          setRoomdata(Meetingroom)
          setIsfetch(true)
        }
      })
    }
  }, [prop.board])
  return (
    <>
      <Modal
        title={`${t('headers.edit', {
          name: nameBoard
        })} : ${editData[`${i18n.language}_name`]}`}
        visible={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false)
          prop.onClose()
          setIsfetch(false)
        }}
        footer={null}
        closable={true}
        width={1400}
      >
        {!isFetch && <Nodata message={'bad gatway 500'} />}
        {isFetch && (
          <Row>
            <Col span={18}>
              <div>
                <div style={{ float: 'right' }}>
                  {_.isEmpty(roomData)
                    ? ''
                    : `${countPage} ${t('form.from')} ${roomData.length}`}
                </div>
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                  }}
                  columns={columns}
                  dataSource={roomData.map((e, i) => {
                    return { ...e, key: e._id }
                  })}
                  pagination={{
                    onChange: (page, pageSize) => {
                      let countP = pageSize * page
                      if (countP > roomData.length) {
                        setCount(
                          countP - (pageSize - 1) + '-' + roomData.length
                        )
                      } else {
                        setCount(countP - (pageSize - 1) + '-' + countP)
                      }
                    },
                    defaultPageSize: 10,
                    showSizeChanger: false,
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form
                layout={'vertical'}
                form={form}
                style={{ marginLeft: '20px' }}
                onFinish={e => {
                  let tmp = {
                    th_name: e.th_name,
                    en_name: e.en_name,
                    username: e.username,
                    description: e.descriptions ? e.descriptions : "",
                    rooms: selectedKeys.map(r => {
                      return { id: r }
                    })
                  }
                  editBoard(editData.id, tmp, (err, res) => {
                    if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                      res = res.data
                      prop.handleUpdated(res)
                      setModalEditOpen(false)
                      setIsfetch(false)
                      message.success('Edit success')
                      setSelectedKeys([])
                      form.resetFields()
                    } else {
                      notification['error']({
                        message: res.error.statusCode || res.status,
                        description: res.message
                      })
                    }
                  })
                }}
              >
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: '500',
                        fontSize: '16px',
                        color: '#4F4F4F'
                      }}
                    >
                      {t('form.th_name')}
                    </div>
                  }
                  autoComplete='off'
                  name='th_name'
                  rules={[
                    {
                      required: true,
                      message: `${t("message.inputthai")}`,
                    }
                  ]}
                >
                  <Input
                    showCount
                    className='inputbox'
                    placeholder={t('form.th_name')}
                    maxLength={30}
                    style={{ borderRadius: '8px' }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: '500',
                        fontSize: '16px',
                        color: '#4F4F4F'
                      }}
                    >
                      {t('form.en_name')}
                    </div>
                  }
                  autoComplete='off'
                  name='en_name'
                  rules={[
                    {
                      required: true,
                      message: `${t("message.inputeng")}`,
                    }
                  ]}
                >
                  <Input
                    showCount
                    className='inputbox'
                    placeholder={t('form.en_name')}
                    maxLength={30}
                    style={{ borderRadius: '8px' }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: '500',
                        fontSize: '16px',
                        color: '#4F4F4F'
                      }}
                    >
                      {t('form.username')}
                    </div>
                  }
                  autoComplete='off'
                  name='username'
                >
                  <Input
                    showCount
                    disabled
                    className='inputbox'
                    placeholder={t('form.username')}
                    maxLength={30}
                    style={{ borderRadius: '8px' }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: '500',
                        fontSize: '16px',
                        color: '#4F4F4F'
                      }}
                    >
                      {t('form.descriptions')}
                    </div>
                  }
                  autoComplete='off'
                  name='descriptions'
                >
                  <Input
                    showCount
                    className='inputbox'
                    placeholder={t('form.descriptions')}
                    style={{ borderRadius: '8px' }}
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{
                      justifyContent: 'flex-end',
                      borderRadius: '8px',
                      float: 'right',
                      boxShadow: "unset",
                      textShadow: "unset",
                    }}
                  >
                    {`${t('button.edit')}${t('menu.board')}`}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  )
}
