import React, { useEffect, useState } from "react";
import { Space, Table, Tooltip, Button, Skeleton, notification,Row,Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { getMeetingroom } from "../../slice/roomSlice";

import AddHouseKeeper from "./addHousekeeper";
import TooltipItem from "../tooltipItem";

import fetchRoombyhousekeeper from "../../api/room/getAllRoombyhousekeeper";

function TableMeetingroom(props) {
  const { t } = useTranslation();
  const [addModal, setAddmodal] = useState(false);
  const [tmpDataid, setTmpdatid] = useState({});
  const [isFetch, setIsfetch] = useState(false);
  const Meetingroom = useSelector(getMeetingroom);
  const [datas, setDatas] = useState([]);
  const [datasFilter, setDatasFilter] = useState([]);
  var profile = localStorage.getItem("profile");
  profile = JSON.parse(profile);
  const columns = [
    {
      title: t("headdertable.meetingroom.name"),
      dataIndex: "displayName",
      key: "displayName",
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      width: "20%",
    },
    {
      title: t("headdertable.meetingroom.project"),
      key: "label",
      dataIndex: "label",
      width: "15%",
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.building"),
      key: "building",
      dataIndex: "building",
      sorter: (a, b) => a.building.localeCompare(b.building),
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.floor"),
      dataIndex: `floorNumber`,
      key: `floorNumber`,
      width: "5%",
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.zone"),
      dataIndex: `floorLabel`,
      key: `floorLabel`,
      width: "5%",
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.item"),
      key: "items",
      dataIndex: "items",
      width: "5%",
      sorter: (a, b) => a.items - b.items,
      render: (text, record) => (
        <Tooltip
          title={<TooltipItem datas={record.itemsList} />}
          placement="right"
          color="white"
          overlayInnerStyle={{
            padding: 0,
            width: "212px",
          }}
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: t("headdertable.meetingroom.housekeeper"),
      key: "housekeepers",
      dataIndex: "housekeepers",
      width: "5%",
      sorter: (a, b) => a.housekeepers - b.housekeepers,
      render: (text) => text,
    },

    {
      title: t("headdertable.meetingroom.action"),
      key: "action",
      render: (_, record) => {
        if (profile.HousekeeperRole.id == 1) {
          return (
            <Space>
              {/* <Tooltip
                title={record.disable ? t('button.link') : t('button.unlink')}
                placement='bottom'
              >
                <Button
                  type={'primary'}
                  ghost={record.disable}
                  icon={<DisconnectOutlined />}
                  size={'middle'}
                  style={{ borderRadius: '8px' }}
                  onClick={() => {}}
                />
              </Tooltip> */}
              <Tooltip title={t("button.viewhousekeeper")} placement="bottom">
                <Button
                  type={"primary"}
                  icon={<UserOutlined />}
                  size={"middle"}
                  style={{
                    borderRadius: "8px",
                    boxShadow: "unset",
                    textShadow: "unset",
                  }}
                  onClick={() => {
                    setAddmodal(!addModal);
                    setTmpdatid(record);
                  }}
                />
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];
  useEffect(() => {
    const currValue = props.strFilter;
    var dataformat = datasFilter.map((e) => {
      return {
        ...e,
        building_name: e.building,
        display_name: e.displayName,
        floorLabel_name: e.floorLabel,
        floorNumber_name: e.floorNumber,
        housekeepers_name: e.housekeepers,
        item_en_name: e.itemsList.map((t) => t.en_name),
        item_th_name: e.itemsList.map((t) => t.th_name),
        label_name: e.label,
      };
    });

    const filteredData = dataformat.filter((entry) =>
      Object.keys(entry)
        .filter((k) => k.includes("name"))
        .some((k) => {
          return String(entry[k])
            .toLowerCase()
            .includes(currValue.toLowerCase());
        })
    );
    setDatas(filteredData);
  }, [props.strFilter]);
  useEffect(() => {
    if (!isFetch && !_.isEmpty(Meetingroom)) {
      setData();
    }
  }, [Meetingroom]);

  const setData = async () => {
    const dataRoominHousekeeper = await fetchRoombyhousekeeper();
    if (dataRoominHousekeeper.statusCode == 200) {
      var roominHousekeeper = dataRoominHousekeeper.data;
      const findKey = Meetingroom.map((m, i) => {
        if (roominHousekeeper.find((t) => t.id === m._id)) {
          let findone = roominHousekeeper.find((t) => t.id === m._id);
          return {
            ...m,
            key: i + 1,
            items: findone.items,
            id: findone.id,
            housekeepers: findone.housekeepers,
            disable: findone.disable,
            itemsList: findone.itemsList,
          };
        } else
          return {
            ...m,
            key: i + 1,
            items: 0,
            id: m._id,
            housekeepers: 0,
            disable: false,
            itemsList: [],
          };
      });
      setDatas(findKey);
      setDatasFilter(findKey);
      setIsfetch(true);
    } else {
      notification["error"]({
        message:
          dataRoominHousekeeper.error.statusCode ||
          dataRoominHousekeeper.status,
        description: dataRoominHousekeeper.message,
      });
    }
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}>
        <div style={{ marginTop: "30px" }}>
          {!isFetch && <Skeleton active />}
          {isFetch && (
            <>
              <Table columns={columns} dataSource={datas} />
            </>
          )}

          <AddHouseKeeper
            modalOpen={addModal}
            meetingroom={tmpDataid}
            onClose={() => {
              setTmpdatid({});
              setAddmodal(false);
            }}
            handleUpdated={(data) => {
              const updatedArray = datas.map((room) =>
                room.id === data.id
                  ? {
                      ...room,
                      housekeepers: data.Housekeepers.length,
                    }
                  : room
              );
              setDatas(updatedArray);
              setTmpdatid({});
              setAddmodal(false);
            }}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={2} xl={2} xxl={2}></Col>
    </Row>
  );
}

export default TableMeetingroom;
