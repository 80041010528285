const deleteGroupItem = (id, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  fetch(
    `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/group/item/${id}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token.token}`,
        Accept: 'application/json'
      }
    }
  )
    .then(res => res.json())
    .then(json => cb(null, json))
    .catch(err => cb(err, null))
}

export default deleteGroupItem
