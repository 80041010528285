import React, { useEffect, useState } from "react";
import { Table, Tag, Tooltip, Row, Col } from "antd";
// import
import { useSelector } from "react-redux";
import { getMeetingroom } from "../../slice/roomSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import DetailOrder from "../../components/overview/detailorder";
import {
  fetchDayly,
  fetchDayRange,
  fetchMonthly,
} from "../../api/history/index";

export default function TableHistory(props) {
  const { t, i18n } = useTranslation();
  const Meetingroom = useSelector(getMeetingroom);
  const [datas, setDatas] = useState([]);
  const [datasFilter, setDatasFilter] = useState([]);
  const [totalPage, setTotalfrom] = useState("");
  const [pageFisrt, setPageFisrt] = useState(1);
  const [tmpData, setTmpdata] = useState({});
  const columns = [
    {
      title: t("headdertable.history.room_name"),
      dataIndex: "room",
      key: "room",
      width: "20%",
      render: (text) => text.name,
    },
    {
      title: t("headdertable.history.subject"),
      dataIndex: `subject`,
      key: `subject`,

      render: (text) => text,
    },
    {
      title: t("headdertable.history.orderno"),
      dataIndex: `order_id`,
      key: `order_id`,
      width: "5%",
      render: (text) => text,
    },
    {
      title: t("headdertable.history.createdAt"),
      key: "createdAt",
      dataIndex: "createdAt",
      width: "15%",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("headdertable.history.updatedAt"),
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: "15%",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (text) =>
        moment(text).format("YYYY-MM-DD HH:mm") !== "Invalid date"
          ? moment(text).format("YYYY-MM-DD HH:mm")
          : "Not yet reviced",
    },
    {
      title: t("headdertable.history.item"),
      key: "items",
      dataIndex: "items",
      sorter: (a, b) =>
        a.items
          .map((d) => d.piece)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) -
        b.items
          .map((d) => d.piece)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ),
      render: (text, record) => (
        <Tooltip
          title={
            <DetailOrder
              order={{
                Orderlists: record.items,
                Room: record.room,
                createdAt: record.createdAt,
                id: record.order_id,
              }}
            />
          }
          placement="right"
          color="white"
          overlayInnerStyle={{
            padding: 0,
            width: "212px",
          }}
        >
          {record.items
            .map((d) => d.piece)
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )}
        </Tooltip>
      ),
    },
    {
      title: t("headdertable.history.housekeeper"),
      key: "housekeeper",
      dataIndex: "housekeeper",
      render: (text) => <>{text[`${i18n.language}_name`]}</>,
    },
    {
      title: t("headdertable.history.status"),
      key: "housekeeper",
      dataIndex: "housekeeper",
      render: (text) => {
        if (text.status.id == 6) {
          return (
            <Tag color="green" style={{ textTransform: "capitalize" }}>
              {text.status[`${i18n.language}_name`]}
            </Tag>
          );
        } else {
          return (
            <Tag color="volcano" style={{ textTransform: "capitalize" }}>
              {text.status[`${i18n.language}_name`]}
            </Tag>
          );
        }
      },
    },
  ];
  useEffect(() => {
    filter_column(props.strFilter, datasFilter);
  }, [props.strFilter]);
  useEffect(() => {
    const filter_data = props.filterDate();
    if (!_.isEmpty(Meetingroom) && !_.isEqual(filter_data, tmpData)) {
      if (filter_data.ActivedateMenu === 2) {
        setData(filter_data);
        setTmpdata(filter_data);
      }
    }
  }, [props.filterDate, Meetingroom]);

  const setData = (tmp_data) => {
    fetchDayRange(
      moment(tmp_data.dateRnage[0]).startOf("day").toISOString(),
      moment(tmp_data.dateRnage[1]).endOf("day").toISOString(),
      Meetingroom,
      (data) => {
        if (data && data.statusCode === 200) {
          data = data.data;
          let tmp = data
            .filter((d) => d.statusId >= 6)
            .map((d, i) => {
              let housekeeper = d.OrderLogs.find((o) => o.statusId >= 6);
              if (
                !_.isEmpty(housekeeper) &&
                (housekeeper.statusId == 8 || housekeeper.statusId == 7)
              ) {
                housekeeper.Housekeeper = {
                  id: 0,
                  th_name: "ไม่มีแม่บ้านรับออเดอร์",
                  en_name: "no maid taking orders",
                };
              } else if (_.isEmpty(housekeeper)) {
                housekeeper = {};
                housekeeper.Housekeeper = {
                  id: 0,
                  th_name: "ไม่มีแม่บ้านรับออเดอร์",
                  en_name: "no maid taking orders",
                };
                housekeeper.Status = {
                  id: 8,
                  th_name: "ล้มเหลว",
                  en_name: "failed",
                };
              }
              return {
                key: i,
                order_id: d.id,
                room: d.Room,
                subject: d.Event?.subject || "no has event",
                createdAt: d.createdAt,
                updatedAt: housekeeper?.createdAt || "cancel", //reviced time
                items: d.Orderlists,
                housekeeper: {
                  id: housekeeper?.Housekeeper.id || "cancel",
                  th_name: housekeeper?.Housekeeper.th_name || "ยกเลิก",
                  en_name: housekeeper?.Housekeeper.en_name || "cancel",
                  status: housekeeper?.Status || "cancel",
                },
              };
            })
            .reverse();
          if (tmp.length > 10) {
            setTotalfrom("1-10");
          } else if (tmp.length < 10) {
            setTotalfrom(1 + "-" + tmp.length);
          } else {
            setTotalfrom("");
          }
          setDatasFilter(tmp);
          filter_column(props.strFilter, tmp);
        }
      }
    );
  };
  const filter_column = (strFilter, datasFilter) => {
    const currValue = strFilter;
    var dataformat = datasFilter.map((t) => {
      return {
        ...t,
        housekeeper_th_name: t.housekeeper.th_name,
        housekeeper_en_name: t.housekeeper.en_name,
        housekeeper_status_th_name: t.housekeeper.status.th_name,
        housekeeper_status_en_name: t.housekeeper.status.en_name,
        items_th_name: t.items.map((k) => k.Item.th_name),
        items_en_name: t.items.map((k) => k.Item.en_name),
        order_id_name: t.order_id,
        room_name: t.room.name,
        subject_name: t.subject,
      };
    });

    const filteredData = dataformat.filter((entry) =>
      Object.keys(entry)
        .filter((k) => k.includes("name"))
        .some((k) => {
          return String(entry[k])
            .toLowerCase()
            .includes(currValue.toLowerCase());
        })
    );

    if (filteredData.length > 10) {
      if (pageFisrt == 1) {
        setTotalfrom(1 + "-" + 10);
      } else {
        setTotalfrom(
          `${10 * (pageFisrt - 1) + 1} - ${
            10 * pageFisrt > filteredData.length
              ? filteredData.length
              : 10 * pageFisrt
          }`
        );
      }
    } else if (filteredData.length < 10 && filteredData.length !== 0) {
      setTotalfrom(1 + "-" + filteredData.length);
    } else {
      setTotalfrom("");
    }

    setDatas(filteredData);
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div style={{ float: "right" }}>
          {totalPage} {`${t("form.from")}`} {datas.length}
        </div>
        <Table
          columns={columns}
          dataSource={datas}
          scroll={{
            x: 1900,
          }}
          pagination={{
            onChange: (page, pageSize) => {
              setTotalfrom(
                `${pageSize * (page - 1) + 1} - ${
                  pageSize * page > datas.length
                    ? datas.length
                    : pageSize * page
                }`
              );
              setPageFisrt(page);
            },
            defaultPageSize: 10,
            showSizeChanger: false,
            pageSizeOptions: ["10"],
          }}
        />
      </Col>
      {/* <Col xs={24} sm={24} md={24} lg={2} xl={2} xxl={2}></Col> */}
    </Row>
  );
}
