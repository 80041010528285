import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Avatar,
  Dropdown,
  Menu,
  Image,
  Upload,
  Modal,
  message,
  notification
} from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { UserOutlined } from '@ant-design/icons'

import ImgCrop from 'antd-img-crop'

import editProfile from '../api/myProfile/editProfile'
import Nodata from "./nodata";

import { getMydata,editdataUser } from '../slice/roomSlice'
import { useSelector,useDispatch } from 'react-redux'
const { Title } = Typography
export default function Modalaccount(props) {
  const dataMyprofile = useSelector(getMydata)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isFetch, setIsfetch] = useState(false)
  const [data, setData] = useState({})
  const [form] = Form.useForm()
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 24
    }
  }

  useEffect(() => {
    setIsModalVisible(props.modalOpen)
    if (!_.isEmpty(dataMyprofile)) {
      form.setFieldsValue(dataMyprofile)
      setData(dataMyprofile)
      setIsfetch(true)
    }
  }, [props])

  const aom = {
    name: "image",
    action: `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/profile`,
    method: "PATCH",
    multiple: false,
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success("Upload Profile");
        form.setFieldsValue(response.data)
        dispatch(editdataUser(response.data))
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`File upload failed.  ${response.message}`);
      }
    },
  };
  return (
    <Modal
      visible={isModalVisible}
      width={700}
      onCancel={() => {
        props.onClose()
      }}
      style={{zIndex: 100}}
      footer={null}
      closable={true}
    >
      {isFetch ? (
        <>
          <Row justify={'center'}>
            <Title
              style={{
                color: 'var(--ant-primary-color)',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '36px'
                // margin: '50px 0'
              }}
            >
              {t("headers.manageaccount")}
            </Title>
          </Row>
          <Image
            width={200}
            style={{ display: 'none' }}
            src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER + 'images/housekeeper/' + data.image}`}
            preview={{
              visible,
              src: `${process.env.REACT_APP_API_URL_HOUSEKEEPER + 'images/housekeeper/' + data.image}`,
              onVisibleChange: value => {
                setVisible(value)
              }
            }}
          />
          <Row align='middle' justify='center'>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => setVisible(true)}>
                  {t("button.seeprofilepicture")}
                  </Menu.Item>
                  <Menu.Item>
                    <ImgCrop rotate>
                      <Upload
                        className='aoooa'
                        type='primary'
                        {...aom}
                        accept='image/png, image/jpeg'
                        showUploadList={false}
                      >
                        {t("button.updateprofilepicture")}
                      </Upload>
                    </ImgCrop>
                  </Menu.Item>
                </Menu>
              }
              placement='bottomRight'
            >
              <Avatar
                size={140}
                icon={<UserOutlined />}
                src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER + 'images/housekeeper/' + data.image}`}
                style={{ cursor: 'pointer' }}
              />
            </Dropdown>
          </Row>
          <Row
            style={{
              fontWeight: '500',
              fontSize: '30px',
              color: 'var(--ant-primary-color)',
              textTransform: 'capitalize'
            }}
            align='middle'
            justify='center'
          >
            {data.username}
          </Row>
          <Row
            align='middle'
            justify='center'
            style={{
              fontWeight: 'normal',
              fontSize: '14px',
              color: '#828282',
              padding: '0 0 30px 0',
              textTransform: 'capitalize'
            }}
          >
            {data.HousekeeperRole[`${i18n.language}_name`]}
          </Row>
          <Row justify='center' style={{ maxHeight: '400px' }}>
            <Form
              autoComplete='off'
              form={form}
              layout='vertical'
              name='profile'
              initialValues={{
                prefix: '+66'
              }}
              onFinish={(e) => {
                editProfile(
                  {
                    username:data.username,
                    email: e.email,
                    th_name: e.th_name,
                    th_surname: e.th_surname,
                    en_name: e.en_name,
                    en_surname: e.en_surname,
                    phoneNumber: e.phoneNumber,
                    role: data.HousekeeperRole.id,
                    // image:data.image,
                  },
                  (err, res) => {
                    if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                      message.success("Edit success");
                      dispatch(editdataUser(res.data))
                      props.onClose();
                    } else {
                      notification["error"]({
                        message: res.error.statusCode || res.status,
                        description: res.message,
                      });
                    }
                  })
              }}
              {...formItemLayout}
            >
              <Col>
                <Row>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.th_name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputnamethai")}`,
                        },
                      ]}
                      name="th_name"
                    >
                      <Input
                        placeholder={t("form.th_name")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.th_surname")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputsurnamethai")}`,
                        },
                      ]}
                      name="th_surname"
                    >
                      <Input
                        placeholder={t("form.th_surname")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.email")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputemail")}`,
                        },
                        {
                          type: "email",
                          message: `${t("message.inputtypeemail")}`,
                        },
                      ]}
                      name="email"
                    >
                      <Input
                        placeholder={t("form.email")}
                        style={{ borderRadius: "8px" }}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.en_name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputnameeng")}`,
                        },
                      ]}
                      name="en_name"
                    >
                      <Input
                        placeholder={t("form.en_name")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.en_surname")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputsurnameeng")}`,
                        },
                      ]}
                      name="en_surname"
                    >
                      <Input
                        placeholder={t("form.en_surname")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      style={{ borderRadius: "8px" }}
                      label={
                        <label
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                            margin: "0 8px 0 0",
                          }}
                        >
                          {t("form.phone")}
                        </label>
                      }
                      name={"phoneNumber"}
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputphone")}`,
                        },
                        {
                          min: 10,
                          message: `${t("message.phonecharacters")}`,
                        },
                        {
                          pattern:"[0-9]{3}[0-9]{3}[0-9]{4}",
                          message: `${t("message.inputformatphone")}`,
                        }
                      ]}
                    >
                      <Input
                        style={{ paddingRight: "23.5px", borderRadius: "8px" }}
                        placeholder={t("form.phone")}
                        showCount
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align='middle'
                  justify='center'
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{
                      background: 'var(--ant-primary-color)',
                      border: '1px solid var(--ant-primary-color)',
                      boxShadow: 'unset',
                      textShadow: 'unset',
                      borderRadius: '8px',
                      color: '#FFFFFF',
                      margin: '13px 0 13px 0',
                      fontWeight: '500',
                      fontSize: '14px'
                    }}
                  >
                    {t("button.updateprofile")}
                  </Button>
                </Row>
              </Col>
            </Form>
          </Row>
        </>
      ):(<Nodata message="bad gateway" />)}

    </Modal>
  )
}
