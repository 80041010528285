import React, { useEffect, useState } from "react";
import {
  Select,
  Input,
  Button,
  Row,
  Form,
  Transfer,
  Modal,
  message,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { useSelector } from "react-redux";
import { getMeetingroom } from "../../slice/roomSlice";

import fetchItem from "../../api/item/getAllItem";
import fetchGroupItemone from "../../api/groupitem/getOneGroupItem";
import editGroupItem from "../../api/groupitem/editGroupItem";

import Nodata from "../nodata";

export default function ModaleditItem(props) {
  const { t, i18n } = useTranslation();

  const Meetingroom = useSelector(getMeetingroom);

  const [modalGroupOpen, setModalGroupOpen] = useState(false);
  const [form] = Form.useForm();
  const [isFetch, setIsfetch] = useState(false);
  const [optionsArea, setOptionsarea] = useState([]);
  const [editData, setEditData] = useState({});
  const [itemData, setItemData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const nameGroupItem = `${t("menu.groupitem")}`;
  useEffect(() => {
    setModalGroupOpen(props.modalOpen);
  }, [props]);
  useEffect(() => {
    if (
      // !_.isEmpty(props.groupitem) &&
      props.groupitem[`${i18n.language}_name`] == "none" &&
      !isFetch
    ) {
      form.resetFields();
      let groupitem = props.groupitem;
      fetchItem((err, res) => {
        if (res && res.statusCode === 200) {
          res = res.data;
          setItemData(
            res.map((item) => {
              return {
                ...item,
                title: item[`${i18n.language}_name`],
                key: item.id,
              };
            })
          );
          setOptionsarea(
            Meetingroom.map((room) => {
              return { label: room.displayName, value: room._id };
            })
          );
        }
      });
      fetchGroupItemone(groupitem.id, (err, response) => {
        if (!err && response.statusCode == 200) {
          response = response.data;
          setEditData(response);
          form.setFieldsValue({
            th_name: response.th_name,
            en_name: response.en_name,
            room: response.rooms.map((room) => room.id),
          });
          setTargetKeys(response.items.map((item) => item.id));
          setIsfetch(true);
        }
      });
    }
  }, [props.groupitem]);

  return (
    <>
      <Modal
        title={`${t("headers.edit", {
          name: nameGroupItem,
        })} : ${editData[`${i18n.language}_name`]}`}
        visible={modalGroupOpen}
        onCancel={() => {
          setModalGroupOpen(false);
          props.onClose();
          setIsfetch(false);
        }}
        footer={null}
        closable={true}
      >
        {!isFetch && <Nodata message={"bad gatway 502"} />}
        {isFetch && (
          <Form
            autoComplete="off"
            form={form}
            name="control-hooks"
            layout="vertical"
            // style={{ padding: '0 24px 20px 24px' }}
            onFinish={(e) => {
              let tmp = {
                th_name: e.th_name,
                en_name: e.en_name,
                listItems: targetKeys.map((item) => {
                  return { id: item };
                }),
                rooms: e.room.map((r) => {
                  return { id: r };
                }),
              };
              editGroupItem(editData.id, tmp, (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  res = res.data;
                  props.handleUpdated(res);
                  setModalGroupOpen(false);
                  props.onClose();
                  setIsfetch(false);
                  message.success("Edit success");
                  setSelectedKeys([]);
                  setTargetKeys([]);
                  form.resetFields();
                } else {
                  notification["error"]({
                    message: res.error.statusCode || res.status,
                    description: res.message,
                  });
                }
              });
            }}
          >
            <Form.Item
              label={
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#4F4F4F",
                  }}
                >
                  {t("form.th_name")}
                </div>
              }
              name="th_name"
              required
              rules={[
                {
                  required: true,
                  message: `${t("message.inputgroupnamethai")}`,
                },
              ]}
            >
              <Input
                showCount
                maxLength={50}
                placeholder={t("form.th_name")}
                style={{ borderRadius: "8px" }}
              />
            </Form.Item>
            <Form.Item
              label={
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#4F4F4F",
                  }}
                >
                  {t("form.en_name")}
                </div>
              }
              name="en_name"
              required
              rules={[
                {
                  required: true,
                  message: `${t("message.inputgroupnameeng")}`,
                },
              ]}
            >
              <Input
                showCount
                maxLength={50}
                placeholder={t("form.en_name")}
                style={{ borderRadius: "8px" }}
              />
            </Form.Item>
            <Form.Item
              label={
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#4F4F4F",
                  }}
                >
                  {t("form.room")}
                </div>
              }
              name="room"
              rules={[
                {
                  required: true,
                  message: `${t("message.inputroom")}`,
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder={`${t('form.selectroom')}`}
                options={optionsArea}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className="ant-select-selector"
              ></Select>
            </Form.Item>

            <div
              style={{
                fontWeight: "500",
                fontSize: "16px",
                color: "#4F4F4F",
                margin: "0 0 10px 5px",
              }}
            >
              {t("form.add_item_group")}
            </div>

            <Transfer
              dataSource={itemData.filter((item) => item.enabledisplay)} // default data
              showSearch
              selectedKeys={selectedKeys}
              targetKeys={targetKeys}
              onChange={(nextTargetKeys) => {
                setTargetKeys(nextTargetKeys);
              }}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
              }}
              render={(item) => item.title}
              style={{ width: "100%", justifyContent: "center" }}
            />

            <Row
              style={{
                justifyContent: "end",
                padding: "0",
                margin: "20px 0 0 0",
              }}
            >
              <Button
                htmlType="button"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  margin: "0 0 0px 10px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  setModalGroupOpen(false);
                  props.onClose();
                  setIsfetch(false);
                  setSelectedKeys([]);
                  setTargetKeys([]);
                  form.resetFields();
                }}
              >
                {t("button.cancel")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  margin: "0 0 0px 10px",
                  fontWeight: "500",
                }}
              >
                {t("button.edit")}{t("menu.groupitem")}
              </Button>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
}
