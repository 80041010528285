import React, { useState } from 'react'
import { Row, Col, Input } from 'antd'
import TableMeetingroom from '../../components/meetingroom/tableMeetingroom'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
function Meetingroom (props) {
  const { t } = useTranslation()
  const [strFilter, setStrfilter] = useState('')
  return (
    <div
      style={{ padding: '20px', margin: '5px 5px 15px 20px', }}
    >
      <Row
        align='middle'
        justify='space-between'
        style={{ marginBottom: '10px' }}
      >
        <Col span={14}>
          <div
            style={{
              fontStyle: 'ExtraBold',
              fontSize: '30px',
              fontWeight: 500,
              lineHeight: '18px',
              color: 'var(--ant-primary-color)'
            }}
          >
            {t('menu.meetingroom')}
          </div>
        </Col>
        <Col span={10}>
          <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
            <Input
              suffix={
                <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
              }
              className='ant-inputsearch search-border'
              placeholder={`${t('button.search')}${t('menu.meetingroom')}`}
              style={{
                margin: '0px 0px 0px 10px',
                borderRadius: '8px',
                border: '1px solid var(--ant-primary-color)',
                maxWidth: '200px'
              }}
              onChange={e => {
                const currValue = e.target.value
                setStrfilter(currValue)
              }}
            />
          </Row>
        </Col>
      </Row>

      <TableMeetingroom strFilter={strFilter} />
    </div>
  )
}

export default Meetingroom
