import React, { useEffect, useState } from 'react'
import { Col, Row, notification, Skeleton } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import Editmodal from './modaleditBoard'
import Deletemodal from './modaldeleteBoard'

import fetchBoard from '../../api/board/getAllBoard'

import _ from 'lodash'
export default function TableBoard(props) {
  const { i18n } = useTranslation()
  const [isFetch, setIsfetch] = useState(false)

  const [datas, setDatas] = useState([])
  const [datasFilter, setDatasFilter] = useState([])
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [tmpDataid, setTmpdatid] = useState({})

  useEffect(() => {
    const currValue = props.strFilter
    var dataformat = datasFilter
    // .map((t) => {
    //   return {
    //     ...t,
    //     Rooms_name: t.Rooms.map((d) => d.name),
    //   }
    // })
    const filteredData = dataformat.filter((entry) =>
      Object.keys(entry)
        .filter((k) => k.includes("name"))
        .some((k) => {
          return String(entry[k])
            .toLowerCase()
            .includes(currValue.toLowerCase());
        })
    );

    setDatas(filteredData)
  }, [props.strFilter])
  useEffect(() => {
    if (isFetch && !_.isEmpty(datas)) {
      const slider = document.querySelector('.rowScroll')
      let isDown = false
      let startX
      let scrollLeft

      slider.addEventListener('mousedown', e => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mousemove', e => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 //scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }
  }, [isFetch])

  useEffect(() => {
    if (_.isObject(props.tmpData) && !_.isEmpty(props.tmpData)) {
      let tmp = props.tmpData
      setDatas([...datasFilter, tmp])
      setDatasFilter([...datasFilter, tmp])
    }
  }, [props.tmpData])

  useEffect(() => {
    if (!isFetch) {
      fetchBoard((err, res) => {
        if (err) {
          notification['error']({
            message: res.error.statusCode || res.status,
            description: err
          })
        }
        if (res && res.statusCode === 200) {
          res = res.data
          setDatas(res.reverse())
          setDatasFilter(res.reverse())
          setIsfetch(true)
          props.onFetch(true)
        }
      })
    }
  }, [])
  return (
    <div
      style={{
        marginTop: '30px'
      }}

    >
      {!isFetch && <Skeleton active />}
      {isFetch && (
        <>
          {_.isEmpty(datas) ? (
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <svg
                className="ant-empty-img-simple"
                width="64"
                height="41"
                viewBox="0 0 64 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                  <ellipse
                    className="ant-empty-img-simple-ellipse"
                    cx="32"
                    cy="33"
                    rx="32"
                    ry="7"
                  ></ellipse>
                  <g className="ant-empty-img-simple-g" fillRule="nonzero">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      className="ant-empty-img-simple-path"
                    ></path>
                  </g>
                </g>
              </svg>
              <div style={{ color: "#00000040", fontSize: "14px", lineHeight: "1.5715" }}>No Data</div>
            </div>
          ) : (
            <Row
              className='rowScroll'
              style={{
                marginTop: '10px',
                // width: '100%',
                maxWidth: '1700px',
                minHeight: '700px',
                flexFlow: ' row nowrap',
                display: 'flex',
                overflowX: 'scroll',
                cursor: 'grab'
              }}
            >
              {datas.map((d, i) => {
                return (
                  <Col
                    span={6}
                    style={{
                      width: '300px',
                      height: '700px',
                      backgroundColor: '#F2F2F2',
                      borderRadius: '8px',
                      margin: '10px'
                    }}
                    key={i}

                  >
                    <Row
                      align='middle'
                      justify='space-between'
                      style={{
                        alignItems: 'center',
                        background: '#E0E0E0',
                        padding: '15px 20px',
                        borderRadius: '8px 8px 0 0',
                        borderBottom: '3.5px solid rgba(255, 255, 255, 0.75)'
                      }}
                    >
                      <div>
                        <h3 >{d[`${i18n.language}_name`]}</h3>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {props.profile.HousekeeperRole.id == 1 && (
                          <>
                            <EditOutlined
                              style={{ fontSize: '24px', marginRight: '5px' }}
                              onClick={() => {
                                setTmpdatid({
                                  id: d.id,
                                  th_name: 'none',
                                  en_name: 'none'
                                })
                                setEditModal(true)
                              }}
                            />

                            <DeleteOutlined
                              style={{ fontSize: '24px' }}
                              onClick={() => {
                                setTmpdatid({
                                  id: d.id,
                                  th_name: d.th_name,
                                  en_name: d.en_name
                                })
                                setDeleteModal(true)
                              }}
                            />
                          </>
                        )}

                      </div>
                    </Row>
                    {d.Rooms.map(room => {
                      return (
                        <Row
                          style={{
                            // borderTop: "3px solid rgba(255, 255, 255, 0.75)",
                            borderBottom: '3.5px solid rgba(255, 255, 255, 0.75)'
                          }}
                        >
                          <Col style={{ margin: '5px' }}>
                            <h3 style={{ marginLeft: '10px', marginTop: '10px' }}>
                              {room.name}
                            </h3>
                          </Col>
                        </Row>
                      )
                    })}
                  </Col>
                )
              })}
            </Row>
          )}
        </>
      )}

      <Editmodal
        modalOpen={editModal}
        board={tmpDataid}
        onClose={() => {
          setTmpdatid({})
          setEditModal(false)
        }}
        handleUpdated={data => {
          const updatedArray = datasFilter.map(board =>
            board.id === data.id
              ? {
                ...board,
                en_name: data.en_name,
                th_name: data.th_name,
                Rooms: data.Rooms,
                ClusterHousekeeperlists: data.ClusterHousekeeperlists
              }
              : board
          )
          const updatedArrayreal = datas.map(board =>
            board.id === data.id
              ? {
                ...board,
                en_name: data.en_name,
                th_name: data.th_name,
                Rooms: data.Rooms,
                ClusterHousekeeperlists: data.ClusterHousekeeperlists
              }
              : board
          )
          setDatas(updatedArrayreal)
          setDatasFilter(updatedArray)
          setTmpdatid({})
          setEditModal(false)
        }}
      />
      <Deletemodal
        modalOpen={deleteModal}
        board={tmpDataid}
        onClose={() => {
          setTmpdatid({})
          setDeleteModal(false)
        }}
        handleUpdated={() => {
          const updatedArray = datasFilter.filter(
            board => board.id !== tmpDataid.id
          )
          const updatedArrayreal = datas.filter(
            board => board.id !== tmpDataid.id
          )
          setDatas(updatedArrayreal)
          setDatasFilter(updatedArray)
          setDeleteModal(false)
          setTmpdatid({})
        }}
      />
    </div>
  )
}
