const fetchGroupItemone = (id, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  fetch(
    `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/group/item/${id}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token.token}`,
        Accept: 'application/json'
      })
    }
  )
    .then(res => res.json())
    .then(json => {
      const tmpData = {
        statusCode: json.statusCode,
        data: {
          key: 0,
          th_name: json.data.th_name,
          en_name: json.data.en_name,
          id: json.data.id,
          items: json.data.Items,
          rooms: json.data.Rooms
        }
      }
      return cb(null, tmpData)
    })
    .catch(err => cb(err, null))
}

export default fetchGroupItemone
