import React, { useEffect, useState } from "react";
import {
  Select,
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Upload,
  Image,
  notification,
  message,
} from "antd";

import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";

import getOneHouseKeeper from "../../api/housekeeper/getOneHouseKeeper";
import editHouseKeeper from "../../api/housekeeper/editHouseKeeper";

import Nodata from "../nodata";

export default function ModaleditHouseKeeper(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [isFetch, setIsfetch] = useState(false);
  const [editData, setEditData] = useState({});
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [file, setFile] = useState('');
  const [name, setName] = useState("");
  const nameHouseKeeper = `${t("menu.housekeeper")}`;
  useEffect(() => {
    setModalEditOpen(prop.modalOpen);
  }, [prop]);
  const dataDragger = {
    name: "filename",
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}item`,
    headers: {
      // Authorization: `Bearer ${token.payload.token}`,
    },
    beforeUpload(file) {
      setFile(file);
      return false;
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // setFileid(response.data.item.id);
      } else if (status === "error") {
        // Notify("error", "Upload Failed", "Server error , " + response.message);
        // message.error(`File upload failed.  ${response.message}`)
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  useEffect(() => {
    if (prop.housekeeper?.th_name == "none" && !isFetch) {
      form.resetFields();
      let housekeeper = prop.housekeeper;
      getOneHouseKeeper(housekeeper.id, (err, response) => {
        if (!err && response.statusCode == 200) {
          response = response.data;
          setEditData(response);
          form.setFieldsValue({ ...response, role: response.role.id });
          setIsfetch(true);
        }
      });
    }
  }, [prop.housekeeper]);
  return (
    <>
      <Modal
        title={`${t("headers.edit", {
          name: nameHouseKeeper,
        })} : ${editData[`${i18n.language}_name`]}`}
        visible={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();
          setIsfetch(false);
          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={1000}
      >
        {!isFetch && <Nodata message={"bad gatway 502"} />}
        {isFetch && (
          <Form
            autoComplete="off"
            form={form}
            layout="vertical"
            onFinish={(e) => {
              editHouseKeeper(
                editData.id,
                {
                  email: e.email,
                  th_name: e.th_name,
                  th_surname: e.th_surname,
                  en_name: e.en_name,
                  en_surname: e.en_surname,
                  phoneNumber: e.phoneNumber,
                  role: e.role,
                  username: e.username,
                  image: file,
                },
                (err, res) => {
                  if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    res = res.data;
                    prop.handleUpdated(res);
                    setModalEditOpen(false);
                    prop.onClose();
                    message.success("Edit success");
                    form.resetFields();
                    setIsfetch(false);
                  } else {
                    notification["error"]({
                      message: res.error.statusCode || res.status,
                      description: res.message,
                    });
                  }
                }
              );
            }}
          >
            <Row>
              <Col span={4}>
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    {...dataDragger}
                    maxCount={1}
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}
                  >
                    {t("form.upload")}
                  </Upload>
                </ImgCrop>
                <Image
                  width={100}
                  src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${editData?.image}`}
                />
              </Col>
              <Col span={20}>
                <Row>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.th_name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputnamethai")}`,
                        },
                      ]}
                      name="th_name"
                    >
                      <Input
                        placeholder={t("form.th_name")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.th_surname")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputsurnamethai")}`,
                        },
                      ]}
                      name="th_surname"
                    >
                      <Input
                        placeholder={t("form.th_surname")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.email")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputemail")}`,
                        },
                        {
                          type: "email",
                          message: `${t("message.inputtypeemail")}`,
                        },
                      ]}
                      name="email"
                    >
                      <Input
                        placeholder={t("form.email")}
                        style={{ borderRadius: "8px" }}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.en_name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputnameeng")}`,
                        },
                      ]}
                      name="en_name"
                    >
                      <Input
                        placeholder={t("form.en_name")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.en_surname")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputsurnameeng")}`,
                        },
                      ]}
                      name="en_surname"
                    >
                      <Input
                        placeholder={t("form.en_surname")}
                        style={{ borderRadius: "8px" }}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      style={{ borderRadius: "8px" }}
                      label={
                        <label
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                            margin: "0 8px 0 0",
                          }}
                        >
                          {t("form.phone")}
                        </label>
                      }
                      name={"phoneNumber"}
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputphone")}`,
                        },
                        {
                          min: 10,
                          message: `${t("message.phonecharacters")}`,
                        },
                        {
                          pattern:"[0-9]{3}[0-9]{3}[0-9]{4}",
                          message: `${t("message.inputformatphone")}`,
                        }
                      ]}
                    >
                      <Input
                        style={{ paddingRight: "23.5px", borderRadius: "8px" }}
                        placeholder={t("form.phone")}
                        showCount
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.role")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputtype")}`,
                        },
                      ]}
                      name="role"
                    >
                      <Select
                        style={{ width: "100%", color: "#828282" }}
                        className="ant-select-selector"
                        options={prop.typeList}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ padding: "0 25.57px" }}>
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.username")}
                        </div>
                      }
                      name="username"
                    >
                      <Input
                        placeholder={t("form.username")}
                        disabled={true}
                        style={{ borderRadius: "8px" }}
                        showCount
                        maxLength={15}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="end" style={{ padding: "0 25.57px" }}>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("button.edit")}{t("menu.housekeeper")}
              </Button>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
}
