import formatDate from '../../utils/formatDate'
export const fetchDayly = async (date, room, cb) => {
  // let token = localStorage.getItem('token')
  // token = JSON.parse(token)
  // var qDate = formatDate(date)
  // fetch(`${process.env.REACT_APP_API_URL}report/summary?date=${qDate}`, {
  //   method: 'GET',
  //   headers: new Headers({
  //     Accept: 'application/json',
  //     Authorization: `Bearer ${token.payload.token}`
  //   })
  // })
  //   .then(res => res.json())
  //   .then(json => {
  //    
  //     cb(json)
  //   })
}
export const fetchDayRange = async (startdate, enddate, room, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  // var sDate = formatDate(startdate)
  // var eDate = formatDate(enddate)
  fetch(
    `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/orders?startDateTime=${startdate}&endDateTime=${enddate}`,
    {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token.token}`
      })
    }
  )
    .then(res => res.json())
    .then(json => {
      cb(json)
    })
}
export const fetchMonthly = async (date, room, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  var qDate = new Date(date)
  fetch(
    `${
      process.env.REACT_APP_API_URL
    }report/summary/monthly?month=${qDate.getMonth()}&year=${qDate.getFullYear()}`,
    {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token.payload.token}`
      })
    }
  )
    .then(res => res.json())
    .then(json => {
      cb(json)
    })
}
export const fetchYearly = async (date, room, cb) => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  var qDate = new Date(date)
  fetch(
    `${
      process.env.REACT_APP_API_URL
    }report/summary/yearly?year=${qDate.getFullYear()}`,
    {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token.payload.token}`
      })
    }
  )
    .then(res => res.json())
    .then(json => {
      cb(json)
    })
}
