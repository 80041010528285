import React, { useEffect, useState } from "react";
import { Button, Row, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";

import deleteBoard from "../../api/board/deleteBoard";

export default function ModaldeleteItem(prop) {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const { t, i18n } = useTranslation();
  const nameBoard = `${t("menu.board")}`;
  useEffect(() => {
    setModalOpen(prop.modalOpen);
    setName(prop.board[`${i18n.language}_name`]);
    setId(prop.board.id);
  }, [prop]);

  return (
    <>
      <Modal
        title={`${t("headers.delete", {
          name: nameBoard,
        })} : ${name}`}
        visible={modalOpen}
        footer={null}
        onCancel={() => {
          setModalOpen(false);
          prop.onClose();
          setId("");
          setName("");
        }}
        // style={{ padding: '0 30px' }}
      >
        <div
          style={{
            fontSize: "16px",
            color: "#82828",
            fontWeight: "normal",
            // margin: '0 0 46px 0'
          }}
        >
          {t("discription.delete_board")}
        </div>
        <Row style={{ justifyContent: "end", padding: "0" }}>
          <Button
            htmlType="button"
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              fontWeight: "500",
            }}
            onClick={() => {
              setModalOpen(false);
              prop.onClose();
              setId("");
              setName("");
            }}
          >
            {t("button.cancel")}
          </Button>
          <Button
            type="primary"
            htmlType="button"
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              fontWeight: "500",
            }}
            danger
            onClick={() => {
              deleteBoard(id, (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  setModalOpen(false);
                  prop.onClose();
                } else {
                  notification["error"]({
                    message: res.error.statusCode || res.status,
                    description: res.message,
                  });
                }
              });
            }}
          >
            {t("button.delete")}
          </Button>
        </Row>
      </Modal>
    </>
  );
}
