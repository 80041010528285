import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Table,
  notification,
  message,
} from "antd";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { useSelector } from "react-redux";
import { getMeetingroom } from "../../slice/roomSlice";

import CreateBoard from "../../api/board/addBoard";

import Nodata from ".././nodata";

export default function Addboard(props) {
  const Meetingroom = useSelector(getMeetingroom);

  const [isFetch, setIsfetch] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [roomData, setRoomdata] = useState([]);
  const [roomDatafilter, setRoomdatafilter] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [totalPage, setTotalfrom] = useState("");
  const [pageFisrt, setPageFisrt] = useState(1);
  const [pageLast, setPageLast] = useState(1);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const nameBoard = `${t("menu.board")}`;
  const columns = [
    {
      title: t("headdertable.meetingroom.name"),
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: t("headdertable.meetingroom.project"),
      key: "label",
      dataIndex: "label",
      width: "15%",
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.building"),
      key: "building",
      dataIndex: "building",
      width: "10%",
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.floor"),
      dataIndex: `floorNumber`,
      key: `floorNumber`,
      width: "5%",
      sorter: (a, b) => a.floorNumber - b.floorNumber,
      render: (text) => text,
    },
    {
      title: t("headdertable.meetingroom.zone"),
      dataIndex: `floorLabel`,
      key: `floorLabel`,
      width: "5%",
      render: (text) => text,
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length <= 16) {
        setSelectedKeys(selectedRowKeys);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.disable,
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    setModalOpen(props.modalOpen);
  }, props);

  useEffect(() => {
    if (!isFetch) {
      if (Meetingroom.length > 10) {
        setTotalfrom("1-10");
      } else if (Meetingroom.length < 10) {
        setTotalfrom(1 + "-" + Meetingroom.length);
      } else {
        setTotalfrom("");
      }
      setRoomdatafilter(Meetingroom);
      setRoomdata(Meetingroom);
      setIsfetch(true);
    }
  }, []);
  return (
    <Modal
      title={`${t("headers.add", { name: nameBoard })}`}
      centered
      visible={modalOpen}
      onCancel={() => {
        props.onClose();
        setIsfetch(false);
      }}
      footer={null}
      closable={true}
      width={1400}
    >
      {!isFetch && <Nodata message={"bad gatway 500"} />}
      {isFetch && (
        <Row>
          <Col span={18}>
            <div>
              <div style={{ float: "right" }}>
                {_.isEmpty(roomData)
                  ? ""
                  : `${totalPage} ${t("form.from")} ${roomData.length}`}
                <Input
                  suffix={
                    <SearchOutlined
                      style={{ color: "rgba(79, 79, 79, 0.5)" }}
                    />
                  }
                  className="ant-inputsearch search-border"
                  placeholder={`${t("button.search")}${t("menu.board")}`}
                  style={{
                    margin: "0px 0px 10px 10px",
                    borderRadius: "8px",
                    border: "1px solid var(--ant-primary-color)",
                    maxWidth: "200px",
                  }}
                  onChange={(e) => {
                    const currValue = e.target.value;
                    const filteredData = roomDatafilter.filter((entry) =>
                      Object.keys(entry).some((k) => {
                        return String(entry[k])
                          .toLowerCase()
                          .includes(currValue.toLowerCase());
                      })
                    );
                    if (filteredData.length > 10) {
                      if (pageFisrt == 1) {
                        setTotalfrom(1 + "-" + 10);
                      } else {
                        setTotalfrom(
                          `${10 * (pageFisrt - 1) + 1} - ${
                            10 * pageFisrt > filteredData.length
                              ? filteredData.length
                              : 10 * pageFisrt
                          }`
                        );
                      }
                    } else if (
                      filteredData.length < 10 &&
                      filteredData.length !== 0
                    ) {
                      setTotalfrom(1 + "-" + filteredData.length);
                    } else {
                      setTotalfrom("");
                    }
                    setRoomdata(filteredData);
                  }}
                />
              </div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={roomData.map((e, i) => {
                  return { ...e, key: e._id };
                })}
                pagination={{
                  onChange: (page, pageSize) => {
                    setTotalfrom(
                      `${pageSize * (page - 1) + 1} - ${
                        pageSize * page > roomData.length
                          ? roomData.length
                          : pageSize * page
                      }`
                    );
                    setPageFisrt(pageSize * (page - 1) + 1);
                    setPageLast(
                      pageSize * page > roomData.length
                        ? roomData.length
                        : pageSize * page
                    );
                  },

                  defaultPageSize: 10,
                  showSizeChanger: false,
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <Form
              layout={"vertical"}
              form={form}
              style={{ marginLeft: "20px" }}
              onFinish={(e) => {
                let tmp = {
                  th_name: e.th_name,
                  en_name: e.en_name,
                  username: e.username,
                  password: e.password,
                  description: e.descriptions,
                  rooms: selectedKeys.map((r) => {
                    return { id: r };
                  }),
                };
                CreateBoard(tmp, (err, result) => {
                  if (err) {
                    notification["error"]({
                      message: "User error",
                      description: err,
                    });
                  }

                  if (
                    !err &&
                    result.statusCode >= 200 &&
                    result.statusCode < 300
                  ) {
                    message.success("This is a success add Borad");
                    result = result.data;
                    props.onSumit(result);
                    props.onClose();
                    setModalOpen(false);
                    setSelectedKeys([]);
                    setIsfetch(false);
                  } else {
                    if (result?.status == "error" || result?.status == "fail") {
                      notification["error"]({
                        message: result.error.statusCode || result.status,
                        description: result.message,
                      });
                    }
                  }
                });
              }}
            >
              <Form.Item
                label={
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#4F4F4F",
                    }}
                  >
                    {t("form.th_name")}
                  </div>
                }
                autoComplete="off"
                name="th_name"
                rules={[
                  {
                    required: true,
                    message: `${t("message.inputthai")}`,
                  },
                  {
                    pattern: "[ก-ฮ]",
                    message: `${t("message.inputthaicharacters")}`,
                  },
                ]}
              >
                <Input
                  showCount
                  className="inputbox"
                  placeholder={t("form.th_name")}
                  maxLength={30}
                  style={{ borderRadius: "8px" }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#4F4F4F",
                    }}
                  >
                    {t("form.en_name")}
                  </div>
                }
                autoComplete="off"
                name="en_name"
                rules={[
                  {
                    required: true,
                    message: `${t("message.inputeng")}`,
                  },
                  {
                    pattern: "[a-zA-Z]",
                    message: `${t("message.inputengcharacters")}`,
                  },
                ]}
              >
                <Input
                  showCount
                  className="inputbox"
                  placeholder={t("form.en_name")}
                  maxLength={30}
                  style={{ borderRadius: "8px" }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#4F4F4F",
                    }}
                  >
                    {t("form.username")}
                  </div>
                }
                tooltip={{
                  title: `${t("message.pattern_username")}`,
                  icon: <InfoCircleOutlined />,
                }}
                autoComplete="off"
                name="username"
                rules={[
                  {
                    required: true,
                    message: `${t("message.inputusername")}`,
                  },
                  {
                    pattern: /^[a-zA-Z0-9]{8,25}$/,
                    message: ``,
                  },
                ]}
              >
                <Input
                  showCount
                  className="inputbox"
                  placeholder={t("form.username")}
                  maxLength={30}
                  style={{ borderRadius: "8px" }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#4F4F4F",
                    }}
                  >
                    {t("form.password")}
                  </div>
                }
                tooltip={{
                  title: `${t("message.pattern_password")}`,
                  icon: <InfoCircleOutlined />,
                  content: "sfsfsfjasjdsajs",
                }}
                autoComplete="off"
                name="password"
                rules={[
                  {
                    required: true,
                    message: `${t("message.inputpassword")}`,
                  },
                  {
                    min: 8,
                    message: `${t("message.passwordminimum")}`,
                  },
                  {
                    max: 19,
                    message: `${t("message.passwordmaximum")}`,
                  },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: ``,
                  },
                ]}
              >
                <Input.Password
                  className="inputbox"
                  placeholder={t("form.password")}
                  style={{ borderRadius: "8px" }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#4F4F4F",
                    }}
                  >
                    {t("form.descriptions")}
                  </div>
                }
                autoComplete="off"
                name="descriptions"
              >
                <Input
                  showCount
                  className="inputbox"
                  placeholder={t("form.descriptions")}
                  style={{ borderRadius: "8px" }}
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    justifyContent: "flex-end",
                    borderRadius: "8px",
                    float: "right",
                    boxShadow: "unset",
                    textShadow: "unset",
                  }}
                >
                  {`${t("button.add")}${t("menu.board")}`}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  );
}
