import React, { useEffect, useState } from 'react'
import { Table, Avatar, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import fetchUser from '../../api/overview/getScore'

import Nodata from '../../components/nodata'
export default function ScoreHousekeeper(props) {
  const { t, i18n } = useTranslation()

  const [isConnected, setIsConnected] = useState(false)

  const [datas, setDatas] = useState([])
  const [lastMessage, setLastMessage] = useState({});
  const [isFetch, setIsfetch] = useState(false)
  const columns = [
    {
      title: '',
      dataIndex: 'image',
      width: '5%',
      render: text => (
        <Avatar
          size='large'
          src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${text}`}
        />
      )
    },
    {
      title: t('headdertable.housekeeper.name'),
      dataIndex: `${i18n.language}_name`,
      key: `${i18n.language}_name`,
      render: (text, record) => {
        if (!record.status.online) {
          return <div style={{ color: '#4F4F4F80' }}>{text}</div>
        } else {
          return text
        }
      }
    },
    {
      title: t('headdertable.housekeeper.username'),
      dataIndex: 'username',
      key: `username`,
      render: (text, record) => {
        if (!record.status.online) {
          return <div style={{ color: '#4F4F4F80' }}>{text}</div>
        } else {
          return text
        }
      }
    },
    {
      title: t('headdertable.housekeeper.score'),
      dataIndex: 'score',
      render: (text, record) => {
        if (!record.status.online) {
          return <div style={{ color: '#4F4F4F80' }}>N/A</div>
        } else {
          return 'N/A'
        }
      }
    },
    {
      title: t('headdertable.housekeeper.status'),
      dataIndex: 'status',
      render: (_, record) => {
        if (!record.status.online) {
          return <div style={{ color: '#4F4F4F80' }}>Offline</div>
        } else if (record.status.busy) {
          return <>Busy</>
        } else {
          return <>Available</>
        }
      }
    }
  ]
  useEffect(() => {
    if (!isFetch) {
      fetchUser((err, user) => {
        if (!err && user.statusCode === 200) {
          let tmp = user.data.sort(function (x, y) {
            return +y.status.online - +x.status.online
          })
          setDatas(tmp)
          setIsfetch(true)
        }
      })
    }
  }, [])
  useEffect(() => {
    if (!_.isEmpty(props.mqtt_client)) {
      if (!isConnected) {
        props.mqtt_client.subscribe(`dtcampus/housekeeper/maid/#`, {}, (err, g) => {
          if (err) {
            console.log("err", err);
          }
          setIsConnected(true)
        })
      }
    }
    props.mqtt_client.on('message', function (topic, message, packet) {
      try {
        let msgJSON = JSON.parse(message.toString())
        if (topic.indexOf(`dtcampus/housekeeper/maid/`) >= 0) {
          setLastMessage(msgJSON)
        }
      } catch (error) {
      }
    })
    return () => {
      if (!_.isEmpty(props.mqtt_client)) {
        props.mqtt_client.unsubscribe(`dtcampus/housekeeper/maid/#`)
      }
    }
  }, [props.mqtt_client])
  useEffect(() => {
    if (isConnected) {
      var msg = lastMessage
      const result = datas.find(ed => msg.id == ed.id)
      if (!_.isEmpty(result)) {
        //Update
        const updatedEventArray = datas.map(ed =>
          ed.id === msg.id
            ? {
              ...ed,
              status: {
                busy: msg.busy,
                online: msg.online,
              }
            }
            : ed
        ).sort(function (x, y) {
          return +y.status.online - +x.status.online
        })
        setDatas(updatedEventArray)
      }
    }
  }, [lastMessage])
  if (isConnected) {
    return (
      <div
        style={{
          border: '1px solid #E0E0E0',
          background: '#FFFFFF',
          height: '28vh',
          overflowX: 'scroll',
          width: '100%',
          margin:'0px 0px 20px 0px'
        }}
      >
        {!isFetch && <Skeleton active style={{ margin: '20px' }} />}
        {isFetch && (
          <Table
            columns={columns}
            dataSource={datas}
            style={{ margin: '10px' }}
            // pagination={{
            //   defaultPageSize: 2,
            //   showSizeChanger: false,
            //   disabled
            // }}
            pagination={false}
          />
        )}
      </div>
    )
  } else {
    return (
      <Nodata message={'mqtt is not connect'} />
    )
  }

}
