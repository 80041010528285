const fetchApi = async (token, cb) => {
    fetch(`${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/profile`, {
        method: "GET",
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            Accept: "application/json",
        }),
    })
        .then((res) => res.json())
        .then(json => cb(null, json))
        .catch(err => cb(err, null))
};


export default fetchApi