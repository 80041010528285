import React, { useEffect, useState } from "react";
import {
  Image,
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Upload,
  notification,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";

import fetchItemone from "../../api/item/getOneItem";
import editItem from "../../api/item/editItem";

import Nodata from "../nodata";

export default function ModaleditItem(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isFetch, setIsfetch] = useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const { t, i18n } = useTranslation();
  const nameItem = `${t("menu.item")}`;
  useEffect(() => {
    setModalEditOpen(prop.modalOpen);
  }, [prop]);
  useEffect(() => {
    if (prop.item?.th_name == "none" && !isFetch) {
      form.resetFields();
      let item = prop.item;
      fetchItemone(item.id, (err, response) => {
        if (!err && response.statusCode == 200) {
          response = response.data;
          setEditData(response);
          form.setFieldsValue(response);
          setIsfetch(true);
        }
      });
    }
  }, [prop.item]);
  const dataDragger = {
    name: "filename",
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}item`,
    headers: {
      // Authorization: `Bearer ${token.payload.token}`,
    },
    beforeUpload(file) {
      setFile(file);
      return false;
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // setFileid(response.data.item.id);
      } else if (status === "error") {
        // Notify("error", "Upload Failed", "Server error , " + response.message);
        // message.error(`File upload failed.  ${response.message}`)
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <>
      <Modal
        title={`${t("headers.edit", {
          name: nameItem,
        })} : ${editData[`${i18n.language}_name`]}`}
        visible={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();
          setIsfetch(false);
          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={1000}
      >
        {!isFetch && <Nodata message={"bad gatway 502"} />}
        {isFetch && (
          <Form
            autoComplete="off"
            layout="vertical"
            size="large"
            form={form}
            onFinish={(e) => {
              editItem(
                editData.id,
                {
                  th_name: e.th_name,
                  en_name: e.en_name,
                  image: file,
                },
                (err, res) => {
                  if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    res = res.data;
                    prop.handleUpdated(res);
                    setModalEditOpen(false);
                    prop.onClose();
                    message.success("Edit success");
                    form.resetFields();
                    setIsfetch(false);
                  } else {
                    notification["error"]({
                      message: res.error.statusCode || res.status,
                      description: res.message,
                    });
                  }
                }
              );
            }}
          >
            <Row style={{ alignItems: "start" }}>
              <Col span={6}>
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    {...dataDragger}
                    maxCount={1}
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}
                  >
                    {t("form.upload")}
                  </Upload>
                </ImgCrop>
                <Image
                  width={100}
                  src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${editData?.image}`}
                />
              </Col>
              <Col span={6} style={{ margin: "0 25px 0 0" }}>
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#4F4F4F",
                      }}
                    >
                      {t("form.th_name")}
                    </div>
                  }
                  autoComplete="off"
                  name="th_name"
                  rules={[
                    {
                      required: true,
                      message: `${t("message.inputitemnamethai")}`,
                    },
                  ]}
                >
                  <Input
                    showCount
                    className="inputbox"
                    placeholder={t("form.th_name")}
                    style={{ borderRadius: "8px" }}
                    maxLength={30}
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ margin: "0 25px 0 0" }}>
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#4F4F4F",
                      }}
                    >
                      {t("form.en_name")}
                    </div>
                  }
                  autoComplete="off"
                  name="en_name"
                  rules={[
                    {
                      required: true,
                      message: `${t("message.inputitemnameeng")}`,
                    },
                  ]}
                >
                  <Input
                    showCount
                    className="inputbox"
                    placeholder={t("form.en_name")}
                    style={{ borderRadius: "8px" }}
                    maxLength={30}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={4} style={{ margin: '0 25px 0 0' }}>
              <Form.Item
                label={
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '16px',
                      color: '#4F4F4F'
                    }}
                  >
                    Timer
                  </div>
                }
                autoComplete='off'
                name='timer'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Name!'
                  }
                ]}
              >
                <TimePicker
                  defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                  style={{ borderRadius: '8px' }}
                  format={'mm:ss'}
                />
              </Form.Item>
            </Col> */}
            </Row>
            <Row justify="end" style={{ padding: "0 25.57px", gap: "8px" }}>
              <Button
                htmlType="button"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  // setModalEditOpen(false)
                  form.resetFields();
                  setIsfetch(false);
                  prop.onClose();
                }}
              >
                {t("button.cancel")}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("button.edit")}{i18n.language == "th" ? "สิ่งของ" : "Item"}
              </Button>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
}
