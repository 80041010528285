import React, { useEffect, useState } from 'react'
import { Row, Col, Layout, Typography, Radio } from 'antd'
import _ from 'lodash'

import Monitor from '../../components/overview/monitor'
import Itemcount from '../../components/overview/itemcount'
import ScoreHousekeeper from '../../components/overview/checkinHousekeeper'
import Nodata from '../../components/nodata'

import { useTranslation } from 'react-i18next'

import { getMQTTClient } from '../.././utils/mqtt'
export default function Overview (props) {
  const [valueDate, setValuedate] = useState('d')
  const [isConnected, setIsConnected] = useState(false)
  const [client, setClient] = useState({})
  const { t } = useTranslation()
  var { Content } = Layout
  const { Title } = Typography
  useEffect(() => {
    if (!isConnected) {
      setClient(getMQTTClient())
    }
  }, [isConnected])
  useEffect(() => {
    if (!_.isEmpty(client) && !isConnected) {
      client.on('error', function (err) {
        client.end()
      })
      client.on('connect', function () {
        if (!isConnected) {
          setIsConnected(true)
        }
      })

      client.on('close', function () {
        console.log('%c' + ' disconnected', 'background: #222; color: #bada55')
      })
    } 

    return () => {
      if (!_.isEmpty(client)) { client.end() }
    }
  }, [client])
  if (isConnected) {
      return (
        <div
          style={{ padding: '20px', margin: '5px 5px 15px 20px' }}
        >
          <Content style={{ padding: '0' }}>
            <Title
              style={{
                color: 'var(--ant-primary-color)',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '24px'
                // margin: '0 0 30px 0'
              }}
            >
              {t("menu.overview")}
            </Title>
            <Row style={{ height: '100%' }} gutter={[32, 24]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={13}
                xxl={14}
                style={{
                  border: '1px solid #E0E0E0',
                  padding: '10px',
                  // height: "740px",
    
                  margin: '0 20px 20px 20px'
                }}
              >
                <Monitor mqtt_client={client} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={9}>
                <h3> {t('headers.overview.housekeeper')}</h3>
                <Row>
                  <ScoreHousekeeper mqtt_client={client}/>
                </Row>
    
                <Row
                  justify={'space-between'}
                  style={{ alignItems: 'center' }}
                >
                  <h3>{t('headers.overview.itemcount')}</h3>
                  <Radio.Group
                    defaultValue='d'
                    buttonStyle='solid'
                    onChange={e => {
                      setValuedate(e.target.value)
                    }}
                  >
                    <Radio.Button value='d' style={{ borderRadius: '8px 0 0 8px' }}>
                      {t('headers.overview.day')}
                    </Radio.Button>
                    <Radio.Button value='w'>
                      {t('headers.overview.week')}
                    </Radio.Button>
                    <Radio.Button value='m' style={{ borderRadius: '0 8px 8px 0' }}>
                      {t('headers.overview.month')}
                    </Radio.Button>
                  </Radio.Group>
                </Row>
                <Row style={{ margin: '10px 0 20px 0' }}>
                  <Itemcount Radiovalue={valueDate} />
                </Row>
              </Col>
            </Row>
          </Content>
        </div>
      )
  }else{
    <Nodata message={'mqtt is not connect'} />
  }
 
}
