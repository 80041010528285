import React, { useState } from 'react'
import { Form, Input, Button, Row, Col, Typography, message } from 'antd'
import { useNavigate } from 'react-router-dom'

import fetchlogin from '../../api/login'
import fetchApi from '../../api/root/fetchApi'

import _ from 'lodash'
const { Title } = Typography
function Login({ setToken, title }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  var navigate = useNavigate()
  return (
    <div style={{ position: 'relative', overflowX: 'clip', height: '100vh' }}>
      <Row
        align='middle'
        justify='center'
        style={{  textAlign: 'center', height: '25%' }}
      >
        <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8}>
          <Title
            style={{
              color: 'var(--ant-primary-color)',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '48px'
            }}
          >
            Welcome!
          </Title>
          {/* <img
            preview={true}
            style={{
              width: "100px",
            }}
            src={`${process.env.REACT_APP_API_URL_PHOTO}${logo}`}
            alt=""
          /> */}
        </Col>
      </Row>
      <Row justify='center' style={{  height: '40%' }}>
        <Col xs={4} sm={4} md={8} lg={8} xl={9} xxl={9}>
          <Row
            style={{
              alignItems: 'center',
              height: '23.5px',
              placeContent: 'end',
              margin: '0 0 32px 0'
            }}
          >
            <div
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                color: '#FF4D4F',
                margin: '0 4px 0 0'
              }}
            >
              *
            </div>
            <div
              style={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#828282',
                margin: '0 8px 0 0'
              }}
            >
              Username :
            </div>
          </Row>
          <Row
            style={{
              alignItems: 'center',
              height: '23.5px',
              placeContent: 'end'
            }}
          >
            <div
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                color: '#FF4D4F',
                margin: '0 4px 0 0'
              }}
            >
              *
            </div>
            <div
              style={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#828282',
                margin: '0 8px 0 0'
              }}
            >
              Password :
            </div>
          </Row>
        </Col>
        <Col
          xs={16}
          sm={16}
          md={8}
          lg={8}
          xl={6}
          xxl={6}
          style={{ textAlign: 'center' }}
        >
          <Form
            layout='horizontal'
            name='basic'
            initialValues={{
              remember: true
            }}
            onFinish={() => {
              fetchlogin(
                {
                  username: username,
                  password: password
                },
                (err, res) => {
                  if (err) {
                    message.error('error')
                  }
                  if (res && res.statusCode === 200) {
                    fetchApi(res.token, (errf, response) => {
                      if ((!errf && !_.isEmpty(response)) && response.data.HousekeeperRole.id !== 3) {
                        localStorage.setItem(
                          'profile',
                          JSON.stringify({
                            username: response.data.username,
                            th_name: response.data.th_name,
                            th_surname: response.data.th_surname,
                            en_name: response.data.en_name,
                            en_surname: response.data.en_surname,
                            phoneNumber: response.data.phoneNumber,
                            email: response.data.email,
                            _id: response.data.id,
                            HousekeeperRole: response.data.HousekeeperRole,
                            image: response.data.image,
                          })
                        )
                        setToken(res)
                        message.success('login is a success ')
                        setTimeout(() => {
                          navigate('/overview')
                        }, 500)
                      } else {
                        message.error('Bad Request')
                      }
                    })
                  } else {
                    message.error(res.message)
                  }
                }
              )
            }}
            autoComplete='off'
          >
            <Form.Item
              name='username'
              // label="Username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
              onChange={e => {
                setUsername(e.target.value)
              }}
              style={{ textAlign: 'start',  fontWeight: 'normal',
              fontSize: '14px',
              color: '#FF4D4F', }}
            >
              <Input className='inputbox' />
            </Form.Item>

            <Form.Item
              name='password'
              // label="Password &nbsp;"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
              onChange={e => {
                setPassword(e.target.value)
              }}
              style={{
                textAlign: 'start', fontWeight: 'normal',
                fontSize: '14px',
                color: '#FF4D4F',
              }}
            >
              <Input.Password />
            </Form.Item>

            <Row align='middle' justify='center'>
              <Col span={24} align='center'>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{
                      borderRadius: '8px',
                      color: '#FFFFFF',
                      margin: '0 0 13px 0',
                      fontWeight: '500',
                      boxShadow: "unset",
                      textShadow: "unset",
                    }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={4} sm={4} md={8} lg={8} xl={9} xxl={9}></Col>
      </Row>
      <Row justify={'end'} style={{ height: '25%' }}>
        <svg
          width='548'
          height='324'
          viewBox='0 0 548 324'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <ellipse
            cx='296'
            cy='383.5'
            rx='296'
            ry='296.5'
            fill='var(--ant-primary-color)'
            fillOpacity='0.3'
          />
          <path
            d='M853 240.8C853 373.79 745.25 481.6 612.333 481.6C479.417 481.6 371.667 373.79 371.667 240.8C371.667 107.81 479.417 0 612.333 0C745.25 0 853 107.81 853 240.8Z'
            fill='var(--ant-primary-color)'
            fillOpacity='0.3'
          />
        </svg>
      </Row>
    </div>
  )
}

export default Login
