import React, { useState } from 'react'
import { Row, Col, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import AddFormdata from '../../components/groupitem/addgroupItem'
import TableItem from '../../components/groupitem/tablegroupItem'
import { useTranslation } from 'react-i18next'

function GroupItem(props) {
  const { t } = useTranslation()
  const [isfetch, setIsfetch] = useState(false)
  const [addButton, setAddbutton] = useState(false)
  const [strFilter, setStrfilter] = useState('')
  const [tmpData, setTmpdata] = useState({})
  var profile = localStorage.getItem('profile')
  profile = JSON.parse(profile)
  return (
    <div
      style={{ padding: '20px', margin: '5px 5px 15px 20px' }}
    >
      <Row align='middle' justify='space-between'>
        <Col span={14}>
          <div
            style={{
              fontStyle: 'ExtraBold',
              fontSize: '30px',
              fontWeight: 500,
              lineHeight: '18px',
              color: 'var(--ant-primary-color)'
            }}
          >
            {t('menu.groupitem')}
          </div>
        </Col>
        <Col span={10}>
          <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
            {profile.HousekeeperRole.id == 1 && (
              <Button
                loading={!isfetch}
                type={!addButton ? 'primary' : ''}
                style={{
                  borderRadius: '8px',
                  fontWeight: '500',
                  boxShadow: "unset",
                  textShadow: "unset",
                }}
                onClick={() => setAddbutton(!addButton)}
              >
                {!addButton ? t('button.add') : t('button.cancel')}
              </Button>
            )}
            <Input
              suffix={
                <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
              }
              className='ant-inputsearch search-border'
              placeholder={`${t('button.search')}${t('menu.groupitem')}`}
              style={{
                margin: '0px 0px 0px 10px',
                borderRadius: '8px',
                border: '1px solid var(--ant-primary-color)',
                maxWidth: '200px'
              }}
              onChange={e => {
                const currValue = e.target.value
                setStrfilter(currValue)
              }}
            />
          </Row>
        </Col>
      </Row>
      {addButton && (
        <Row>
          <AddFormdata
            modelOpen={addButton}
            onClose={() => {
              setAddbutton(!addButton)
            }}
            onSumit={d => {
              setTmpdata(d)
            }}
          />
        </Row>
      )}
      <TableItem
        profile={profile}
        strFilter={strFilter}
        tmpData={tmpData}
        SETtmpData={() => {
          setTmpdata({})
        }}
        onClosebuttonAdd={() => {
          setAddbutton(false)
        }}
        onFetch={f => {
          setIsfetch(f)
        }}
      />
    </div>
  )
}

export default GroupItem
