import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Tooltip,
  Button,
  Avatar,
  message,
  Skeleton,
  notification,
  Row,
  Col,
} from "antd";
import {
  DisconnectOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Editmodal from "./modaleditItem";
import Deletemodal from "./modaldeleteItem";

import dateRole from "../../api/role.json";
import disableItem from "../../api/item/disableItem";
import fetchItem from "../../api/item/getAllItem";

export default function TableItem(props) {
  const { t, i18n } = useTranslation();
  const [isFetch, setIsfetch] = useState(false);
  const [datas, setDatas] = useState([]);
  const [datasFilter, setDatasFilter] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tmpDataid, setTmpdatid] = useState({});
  const [totalPage, setTotalfrom] = useState("");
  const [pageFisrt, setPageFisrt] = useState(1);
  const [pageLast, setPageLast] = useState(1);
  const columns = [
    // {
    //   title: 'No.',
    //   dataIndex: 'key',
    //   key: 'key',
    //   width: '5%',
    //   sorter: (a, b) => a.key - b.key
    // },
    {
      title: t("headdertable.item.image"),
      dataIndex: "image",
      key: "image",
      width: "10%",
      render: (text) => (
        <Avatar
          size="large"
          src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${text}`}
        />
      ),
    },
    {
      title: t("headdertable.item.name"),
      dataIndex: `${i18n.language}_name`,
      key: `${i18n.language}_name`,
      render: (text) => text,
    },
    // {
    //   title: t('headdertable.item.timer'),
    //   dataIndex: `timer`,
    //   key: `timer`,
    //   render: text => text
    // },
    {
      title: t("headdertable.item.action"),
      key: "action",
      render: (_, record) => {
        if (props.profile.HousekeeperRole.id == 1) {
          return (
            <Space>
              <Tooltip
                title={
                  record.enabledisplay ? t("button.link") : t("button.unlink")
                }
                placement="bottom"
              >
                <Button
                  type={"primary"}
                  ghost={record.enabledisplay}
                  icon={<DisconnectOutlined />}
                  size={"middle"}
                  style={{ borderRadius: "8px", boxShadow: "unset",
                  textShadow: "unset",}}
                  onClick={() => {
                    disableItem(record.id, (err, res) => {
                      if (
                        !err &&
                        res.statusCode >= 200 &&
                        res.statusCode < 300
                      ) {
                        const updatedArray = datasFilter.map((item) =>
                          item.id == record.id
                            ? {
                                ...item,
                                enabledisplay: !record.enabledisplay,
                              }
                            : item
                        );
                        const updatedArrayreal = datas.map((item) =>
                          item.id == record.id
                            ? {
                                ...item,
                                enabledisplay: !record.enabledisplay,
                              }
                            : item
                        );
                        setDatas(updatedArrayreal);
                        setDatasFilter(updatedArray);
                      } else {
                        notification["error"]({
                          message: res.error.statusCode || res.status,
                          description: res.message,
                        });
                      }
                    });
                  }}
                />
              </Tooltip>
              <Tooltip title={t("button.edit")} placement="bottom">
                <Button
                  type="primary"
                  icon={<EditFilled />}
                  size={"middle"}
                  style={{ borderRadius: "8px", boxShadow: "unset",
                  textShadow: "unset",}}
                  onClick={() => {
                    setTmpdatid({
                      id: record.id,
                      th_name: "none",
                      en_name: "none",
                    });
                    props.onClosebuttonAdd();
                    setEditModal(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={t("button.delete")} placement="bottom">
                <Button
                  type="primary"
                  icon={<DeleteFilled />}
                  size={"middle"}
                  danger
                  style={{ borderRadius: "8px" , boxShadow: "unset",
                  textShadow: "unset",}}
                  onClick={() => {
                    setTmpdatid({
                      id: record.id,
                      th_name: record.th_name,
                      en_name: record.en_name,
                    });
                    props.onClosebuttonAdd();
                    setDeleteModal(true);
                  }}
                />
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];
  useEffect(() => {
    if (!isFetch) {
      fetchItem((err, res) => {
        if (err) {
          message.error("Bad Gateway: " + err);
        }
        if (res && res.statusCode === 200) {
          res = res.data;
          if (res.length > 10) {
            setTotalfrom("1-10");
          } else {
            setTotalfrom("1-" + res.length);
          }
          setDatas(res);
          setDatasFilter(res);
          setIsfetch(true);
          props.onFetch(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    const currValue = props.strFilter;
    // searchFilter

    const filteredData = datasFilter.filter((entry) =>
      Object.keys(entry)
        .filter((k) => k.includes("name"))
        .some((k) => {
          return String(entry[k])
            .toLowerCase()
            .includes(currValue.toLowerCase());
        })
    );
    if (filteredData.length > 10) {
      if (pageFisrt == 1) {
        setTotalfrom(1 + "-" + 10);
      } else {
        setTotalfrom(
          `${10 * (pageFisrt - 1) + 1} - ${
            10 * pageFisrt > filteredData.length
              ? filteredData.length
              : 10 * pageFisrt
          }`
        );
      }
    } else if (filteredData.length < 10 && filteredData.length !== 0) {
      setTotalfrom(1 + "-" + filteredData.length);
    } else {
      setTotalfrom("");
    }
    setDatas(filteredData);
  }, [props.strFilter]);
  useEffect(() => {
    if (_.isObject(props.tmpData) && !_.isEmpty(props.tmpData)) {
      // addData
      setDatas([...datasFilter, props.tmpData]);
      setDatasFilter([...datasFilter, props.tmpData]);
      if (datasFilter.length > 10 && 10 * (pageFisrt - 1) + 1 !== 1) {
        setTotalfrom(`${10 * (pageFisrt - 1) + 1} -  ${pageLast + 1} `);
        setPageLast(pageLast + 1);
      } else if (datasFilter.length < 10) {
        setPageFisrt(1);
        setTotalfrom("1-" + (datasFilter.length + 1));
      } else {
        setPageFisrt(1);
        setTotalfrom("1-10");
      }
      props.SETtmpData();
    }
  }, [props.tmpData]);
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}>
        <div style={{ marginTop: "20px", width: "100%" }}>
          {!isFetch && <Skeleton active />}
          {isFetch && (
            <>
              <div style={{ float: "right" }}>
                {totalPage} {`${t("form.from")}`} {datas.length}
              </div>
              <Table
                columns={columns}
                dataSource={datas}
                pagination={{
                  onChange: (page, pageSize) => {
                    setTotalfrom(
                      `${pageSize * (page - 1) + 1} - ${
                        pageSize * page > datas.length
                          ? datas.length
                          : pageSize * page
                      }`
                    );
                    setPageFisrt(page);
                    setPageLast(
                      pageSize * page > datas.length
                        ? datas.length
                        : pageSize * page
                    );
                  },
                  defaultPageSize: 10,
                  showSizeChanger: false,
                  pageSizeOptions: ["10"],
                }}
              />
            </>
          )}
          <Editmodal
            modalOpen={editModal}
            item={tmpDataid}
            onClose={() => {
              setTmpdatid({});
              setEditModal(false);
            }}
            handleUpdated={(data) => {
              const updatedArray = datasFilter.map((item) =>
                item.id === data.id
                  ? {
                      ...item,
                      en_name: data.en_name,
                      id: data.id,
                      image: data.image,
                      th_name: data.th_name,
                    }
                  : item
              );
              const updatedArrayreal = datas.map((item) =>
                item.id === data.id
                  ? {
                      ...item,
                      en_name: data.en_name,
                      id: data.id,
                      image: data.image,
                      th_name: data.th_name,
                    }
                  : item
              );
              setDatas(updatedArrayreal);
              setDatasFilter(updatedArray);
              setEditModal(false);
              setTmpdatid({});
            }}
            typeList={dateRole.data}
          />
          <Deletemodal
            modalOpen={deleteModal}
            item={tmpDataid}
            onClose={() => {
              setTmpdatid({});
              setDeleteModal(false);
            }}
            handleUpdated={() => {
              const updatedArray = datasFilter.filter(
                (item) => item.id !== tmpDataid.id
              );
              const updatedArrayreal = datas.filter(
                (item) => item.id !== tmpDataid.id
              );
              if (updatedArray.length > 10 && 10 * (pageFisrt - 1) + 1 !== 1) {
                setTotalfrom(`${10 * (pageFisrt - 1) + 1} -  ${pageLast - 1} `);
                setPageLast(pageLast - 1);
              } else if (updatedArray.length < 10) {
                setPageFisrt(1);
                setTotalfrom("1-" + updatedArray.length);
              } else {
                setPageFisrt(1);
                setTotalfrom("1-10");
              }
              setDatas(updatedArrayreal);
              setDatasFilter(updatedArray);
              setTmpdatid({});
              setDeleteModal(false);
            }}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={2} xl={2} xxl={2}></Col>
    </Row>
  );
}
