import React, { useEffect, useState } from 'react'
import { Button, Row, Modal, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import cancelOrder from '../../api/order/cancelOrder'
export default function ModalcancelOrder (prop) {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const nameOrder = `${t('form.order_no')}`
  useEffect(() => {
    setModalOpen(prop.modalOpen)
    setName(prop.order.id)
    setId(prop.order.id)
  }, [prop])

  return (
    <>
      <Modal
        title={`${t('headers.cancel', {
          name: nameOrder
        })} ${name}`}
        visible={modalOpen}
        footer={null}
        onCancel={() => {
          // setModalOpen(false)
          prop.onClose()
        }}
        // style={{ padding: '0 30px' }}
      >
        <div
          style={{
            fontSize: '16px',
            color: '#82828',
            fontWeight: 'normal'
            // margin: '0 0 46px 0'
          }}
        >
          {t('discription.delete_order')}
        </div>
        <Row style={{ justifyContent: 'end', padding: '0' }}>
          {/* <Button
            htmlType="button"
            ghost
            style={{
              boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              background: "#BDBDBD",
              border: "1px solid #BDBDBD",
              color: "#FFFFFF",
              fontWeight: "500",
            }}
            onClick={() => {
              // setModalOpen(false)
              prop.onClose();
            }}
          >
            {t("button.cancel")}
          </Button> */}
          <Button
            type='primary'
            htmlType='button'
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: '8px',
              fontSize: '14px',
              margin: '0 0 0px 10px',
              fontWeight: '500'
            }}
            danger
            onClick={() => {
              cancelOrder(id, (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  setModalOpen(false)
                  prop.onClose()
                } else {
                  notification['error']({
                    message: res.error.statusCode || res.status,
                    description: res.message
                  })
                }
              })
            }}
          >
            {t('button.cancel')}
          </Button>
        </Row>
      </Modal>
    </>
  )
}
