import React, { useState } from 'react'
import { Row, Col, Radio, Input, DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import formatDate from '../../utils/formatDate'
import disabledDate from '../../utils/disabledDate'
import moment from 'moment'

import TableHistory from '../../components/history/tableHistory'

function History (props) {
  const { t } = useTranslation()
  const [strFilter, setStrfilter] = useState('')
  const [ActivedateMenu, setActivedateMenu] = useState(2)
  const [qDate, setqDate] = useState(formatDate(Date.now()))
  const [dateRnage, setDateRange] = useState([
    formatDate(new Date().setDate(new Date().getDate() - 3)),
    formatDate(Date.now())
  ])
  const [qMonth, setqMonth] = useState(formatDate(Date.now()).slice(0, 7))

  return (
    <div
      style={{ padding: '20px', margin: '5px 5px 15px 20px',  }}
    >
      <Row
        align='middle'
        justify='space-between'
        style={{ marginBottom: '10px' }}
      >
        <Col span={8}>
          <div
            style={{
              fontStyle: 'ExtraBold',
              fontSize: '30px',
              fontWeight: 500,
              lineHeight: '18px',
              color: 'var(--ant-primary-color)'
            }}
          >
            {t('menu.history')}
          </div>
        </Col>
        <Col span={16}>
          <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
            <Radio.Group
              defaultValue={ActivedateMenu}
              style={{ borderRadius: '8px 0 0 8px', marginTop: '10px' }}
              buttonStyle='solid'
              onChange={e => setActivedateMenu(e.target.value)}
            >
              {/* <Radio.Button value={1} style={{ borderRadius: '8px 0 0 8px' }}>
                Day
              </Radio.Button> */}
              {/* <Radio.Button value={2} style={{ borderRadius: '8px' }}>
                Day Range
              </Radio.Button> */}
              {/* <Radio.Button value={3} style={{ borderRadius: '0 8px 8px 0' }}>
                Month
              </Radio.Button> */}
            </Radio.Group>
            {ActivedateMenu === 1 ? (
              <DatePicker
                style={{ margin: '10px', borderRadius: '8px' }}
                defaultValue={moment(qDate)}
                onChange={(date, dateStrings) => {
                  setqDate(dateStrings)
                }}
                disabledDate={disabledDate}
              />
            ) : null}
            {ActivedateMenu === 2 ? (
              <DatePicker.RangePicker
                style={{ margin: '10px', borderRadius: '8px' }}
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month')
                  ]
                }}
                defaultValue={[moment(dateRnage[0]), moment(dateRnage[1])]}
                onChange={(date, dateStrings) => {
                  setDateRange(dateStrings)
                }}
                disabledDate={disabledDate}
              />
            ) : null}
            {ActivedateMenu === 3 ? (
              <DatePicker
                style={{ margin: '10px', borderRadius: '8px' }}
                defaultValue={moment(qMonth)}
                onChange={(date, dateStrings) => {
                  setqMonth(dateStrings)
                }}
                picker='month'
                disabledDate={disabledDate}
              />
            ) : null}
            <Input
              suffix={
                <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
              }
              className='ant-inputsearch search-border'
              placeholder={`${t('button.search')}${t('menu.history')}`}
              style={{
                margin: '10px 0px 0px 10px',
                borderRadius: '8px',
                border: '1px solid var(--ant-primary-color)',
                maxWidth: '200px',
                maxHeight: '32px'
              }}
              onChange={e => {
                const currValue = e.target.value
                setStrfilter(currValue)
              }}
            />
          </Row>
        </Col>
      </Row>
      <TableHistory
        strFilter={strFilter}
        filterDate={() => {
          if (ActivedateMenu === 1) {
            return { ActivedateMenu, qDate }
          } else if (ActivedateMenu === 2) {
            return { ActivedateMenu, dateRnage }
          } else if (ActivedateMenu === 3) {
            return { ActivedateMenu, qMonth }
          }
        }}
      />
    </div>
  )
}

export default History
