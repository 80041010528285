import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Collapse,
  Button,
  Skeleton,
  Tag,
  Form,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./tableOrder.css";
import _ from "lodash";

import Nodata from "../../components/nodata";
import Cancelmodal from "./modalcancelOrder";
import fetchOrder from "../../api/order/getAllOrder";

import { getMQTTClient } from "../.././utils/mqtt";
export default function TableOrder(props) {
  const { t, i18n } = useTranslation();

  const [isConnected, setIsConnected] = useState(false);
  const [client, setClient] = useState({});
  const [lastMessage, setLastMessage] = useState({});

  const [datas, setDatas] = useState([]);
  const [datasFilter, setDatasFilter] = useState([]);
  const [isFetch, setIsfetch] = useState(false);
  const [deleteButton, setDeletebutton] = useState(false);
  const [tmpDataid, setTmpdatid] = useState({});
  const { Panel } = Collapse;

  useEffect(() => {
    if (!isConnected) {
      setClient(getMQTTClient());
    }
  }, [isConnected]);
  useEffect(() => {
    if (!_.isEmpty(client) && !isConnected) {
      client.on("error", function (err) {
        client.end();
      });

      client.on("connect", function () {
        if (!isConnected) {
          console.log("connected");
          client.subscribe("dtcampus/housekeeper/room/#", {}, (err, g) => {
            if (err) {
              console.log("err", err);
            }
          });
          setIsConnected(true);
        }
      });
      client.on("message", function (topic, message, packet) {
        const msg = JSON.parse(message.toString());
        setLastMessage(msg);
      });

      client.on("close", function () {
        setIsConnected(false);
        console.log("%c" + " disconnected", "background: #222; color: #bada55");
      });
    }

    return () => {
      if (!_.isEmpty(client)) {
        client.end();
      }
    };
  }, [client]);
  useEffect(() => {
    if (isConnected) {
      var msg = lastMessage;

      const result = datasFilter.find((ed) => msg.id == ed.id);
      if (msg.statusId === 1 && _.isEmpty(result)) {
        //Create
        setDatas([...datasFilter, msg]);
        setDatasFilter([...datasFilter, msg]);
      }
      if (!_.isEmpty(result)) {
        if (msg.statusId === 4) {
          //Update
          const updatedEventArray = datasFilter.map((ed) =>
            ed.id === msg.id
              ? {
                  ...ed,
                  statusId: msg.statusId,
                  Status: msg.Status,
                  OrderLogs: msg.OrderLogs,
                }
              : ed
          );
          setDatas(updatedEventArray);
          setDatasFilter(updatedEventArray);
        } else if (msg.statusId >= 6) {
          const updatedEventArray = datasFilter.filter(
            (ed) => ed.id !== msg.id
          );
          setDatas(updatedEventArray);
          setDatasFilter(updatedEventArray);
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const currValue = props.strFilter;
    const filteredData = datasFilter.filter((entry) =>
      Object.keys(entry).some((k) => {
        if (k == "room_name") {
          return String(entry[k])
            .toLowerCase()
            .includes(currValue.toLowerCase());
        } else {
          return;
        }
      })
    );
    setDatas(filteredData);
  }, [props.strFilter]);
  useEffect(() => {
    if (!isFetch) {
      let startDate = moment().startOf("day").toISOString();
      let endDate = moment().endOf("day").toISOString();
      fetchOrder(null, null, startDate, endDate, (err, res) => {
        if (err) {
          notification["error"]({
            message: res.error.statusCode || res.status,
            description: err,
          });
        }
        if (res && res.statusCode === 200) {
          res = res.data;
          let tmp = res
            .filter((e) => e.statusId >= 1 && e.statusId <= 4)
            .map((o) => {
              return { ...o, room_name: o.Room.name };
            });
          setDatas(tmp);
          setDatasFilter(tmp);
          setIsfetch(true);
          props.onFetch(true);
        }
      });
    }
  }, []);
  if (isConnected) {
    return (
      <div>
        {!isFetch && <Skeleton active />}
        {isFetch && (
          <>
            {_.isEmpty(datas) ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <svg
                  className="ant-empty-img-simple"
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse
                      className="ant-empty-img-simple-ellipse"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g className="ant-empty-img-simple-g" fillRule="nonzero">
                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        className="ant-empty-img-simple-path"
                      ></path>
                    </g>
                  </g>
                </svg>
                <div
                  style={{
                    color: "#00000040",
                    fontSize: "14px",
                    lineHeight: "1.5715",
                  }}
                >
                  No Data
                </div>
              </div>
            ) : (
              <Collapse accordion>
                {datas
                  .sort(function (a, b) {
                    return new Date(b.id) - new Date(a.id);
                  })
                  .map((item, i) => {
                    let housekeeper = item.OrderLogs.find(
                      (o) => o.statusId == 4
                    ) || {
                      Housekeeper: { th_name: "-", en_name: "-" },
                      createdAt: "-",
                    };
                    return (
                      <>
                        <Panel
                          key={item.id + "_panel"}
                          className={`collapseheader`}
                          header={
                            <div
                              style={{
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "var(--ant-primary-color)",
                                alignItems: "center",
                              }}
                            >
                              {t("form.order_no")}. {item.id}
                            </div>
                          }
                          extra={
                            <Row
                              style={{ minWidth: "280px" }}
                              key={item.id + "extra_row"}
                            >
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={14}
                                xl={14}
                                xxl={14}
                              >
                                <Row>
                                  <div
                                    style={{
                                      color: "#BDBDBD",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t("form.status")} :
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      margin: "0px 0px 0px 5px",
                                      textTransform: "capitalize",
                                      color: "#828282",
                                    }}
                                  >
                                    {item.Status[`${i18n.language}_name`]}
                                  </div>
                                </Row>
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={10}
                                xl={10}
                                xxl={10}
                              >
                                <Row
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#BDBDBD",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t("form.create")} :
                                  </div>
                                  <div
                                    style={{
                                      color: "#828282",
                                      margin: "0px 0px 0px 5px",
                                    }}
                                  >
                                    {moment(item.createdAt).format("HH:mm")}
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          }
                        >
                          <Row key={item.id + "row_panel"}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}
                              style={{ padding: "0 38px 0 0" }}
                            >
                              <Row style={{ margin: "0 0 10px 0" }}>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#828282",
                                  }}
                                >
                                  {t("form.meetingroom")} :
                                </div>
                                <div
                                  style={{
                                    margin: "0 8px",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    color: "#4F4F4F",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.Room.name}
                                </div>
                              </Row>
                              <Row style={{ margin: "0 0 10px 0" }}>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#828282",
                                  }}
                                >
                                  {t("form.subject")} :
                                </div>
                                <div
                                  style={{
                                    margin: "0 8px",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    color: "#4F4F4F",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.Event.subject}
                                </div>
                              </Row>
                              <Row style={{ margin: "0 0 10px 0" }}>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#828282",
                                  }}
                                >
                                  {t("form.housekeeper")} :
                                </div>
                                <div
                                  style={{
                                    margin: "0 8px",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    color: "#4F4F4F",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {housekeeper?.Housekeeper[
                                    `${i18n.language}_name`
                                  ] || "-"}
                                  {housekeeper?.Housekeeper[
                                    `${i18n.language}_surname`
                                  ] || " "}
                                </div>
                              </Row>
                              <Row style={{ margin: "0 0 10px 0" }}>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#828282",
                                  }}
                                >
                                  {t("form.receivedtime")} :
                                </div>
                                <div
                                  style={{
                                    margin: "0 8px",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    color: "#4F4F4F",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {housekeeper?.createdAt == "-" ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      {moment(housekeeper.createdAt).format(
                                        "DD MMM YYYY HH:mm"
                                      )}
                                    </>
                                  )}
                                </div>
                              </Row>
                              {item.statusId === 1 && (
                                <Row
                                  style={{
                                    // justifyContent: "center",
                                    margin: "16px 0 16px 0",
                                    gap: "2px",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    style={{
                                      fontWeight: "500",
                                      boxShadow: "unset",
                                      textShadow: "unset",
                                      borderRadius: "8px",
                                      fontSize: "14px",
                                    }}
                                    danger
                                    onClick={() => {
                                      setDeletebutton(true);
                                      setTmpdatid(item);
                                    }}
                                  >
                                    {t("button.cancel")}
                                  </Button>
                                </Row>
                              )}
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}
                              style={{
                                padding: "0px 25px",
                                border: "1px solid #E0E0E0",
                                borderRadius: "8px",
                                height: "180px",
                                overflowX: "hidden",
                              }}
                            >
                              {item.Orderlists?.map((pd, index) => {
                                return (
                                  <>
                                    <Row
                                      style={{
                                        margin: "20px 0",
                                        // justifyContent: "space-between",
                                        // alignItems: "center",
                                      }}
                                      key={index.ItemId + "_pd"}
                                    >
                                      <Col
                                        xs={14}
                                        sm={14}
                                        md={20}
                                        lg={18}
                                        xl={21}
                                        xxl={22}
                                        style={{ textAlign: "start" }}
                                      >
                                        <Row>
                                          <Col
                                            xs={10}
                                            sm={10}
                                            md={5}
                                            lg={8}
                                            xl={4}
                                            xxl={3}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${pd.Item.image}`}
                                              alt=""
                                              style={{
                                                padding: "0",
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          </Col>
                                          <Col
                                            xs={14}
                                            sm={14}
                                            md={19}
                                            lg={16}
                                            xl={20}
                                            xxl={21}
                                            style={{
                                              alignSelf: "center",
                                              padding: "0 8px 0 0",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "normal",
                                                fontSize: "14px",
                                                color: "#828282",
                                              }}
                                            >
                                              {pd.Item[`${i18n.language}_name`]}
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col
                                        xs={10}
                                        sm={10}
                                        md={4}
                                        lg={6}
                                        xl={3}
                                        xxl={2}
                                        style={{ textAlign: "end" }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "normal",
                                            fontSize: "14px",
                                            color: "#828282",
                                          }}
                                        >
                                          {pd.piece}
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })}
                            </Col>
                          </Row>
                        </Panel>
                      </>
                    );
                  })}
              </Collapse>
            )}
          </>
        )}

        <Cancelmodal
          modalOpen={deleteButton}
          order={tmpDataid}
          onClose={() => {
            setDeletebutton(false);
          }}
        />
      </div>
    );
  } else {
    <Nodata message={"mqtt is not connect"} />;
  }
}
