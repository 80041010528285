import moment from 'moment'

const fetchScore =  cb => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  let startDateTime = moment()
  .startOf('day')
  .toISOString()
  let endDateTime = moment()
  .endOf('day')
  .toISOString()

  fetch(`${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/report/housekeeper?startDateTime=${startDateTime}&endDateTime=${endDateTime}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token.token}`,
      Accept: 'application/json'
    })
  })
    .then(res => res.json())
    .then(json => {
      const tmpData = {
        statusCode: json.statusCode,
        data: json.data.map((e, i) => {
          return {
            key: i + 1,
            th_name: e.th_name,
            en_name: e.en_name,
            id: e.id,
            image: e.image,
            username: e.username,
            phoneNumber: e.phoneNumber,
            role: e.HousekeeperRole,
            available: e.available,
            status: {
              busy: e.busy,
              online: e.online,
              available: e.available,
            },
            rating:e.rating,
          }
        })
      }
      return cb(null, tmpData)
    })
    .catch(err => cb(err, null))
}

export default fetchScore
