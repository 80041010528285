const fetchRoombyhousekeeper = async () => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  return fetch(
    `${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/rooms`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token.token}`,
        Accept: 'application/json'
      })
    }
  )
    .then(res => res.json())
    .catch(err => err)
}

export default fetchRoombyhousekeeper
