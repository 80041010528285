import React, { useEffect, useState } from "react";
import {
  TimePicker,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Form,
  message,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";

import addItem from "../../api/item/addItem";

function Additem(props) {
  const [fileId, setFileid] = useState([]);
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const dataDragger = {
    name: "filename",
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}item`,
    headers: {
      // Authorization: `Bearer ${token.payload.token}`,
    },
    beforeUpload(file) {
      setFileid(file);
      return false;
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // setFileid(response.data.item.id);
      } else if (status === "error") {
        // Notify("error", "Upload Failed", "Server error , " + response.message);
        message.error(`File upload failed.  ${response.message}`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={22} lg={20} xl={18} xxl={18}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#e3e1e1",
            margin: "20px",
            padding: "20px 24px",
            borderRadius: "8px",
            animation: " fadeInDown 0.5s",
            transition: "fadeOutUp 0.5s",
            // maxWidth: '60%'
          }}
        >
          <Form
            autoComplete="off"
            layout="vertical"
            form={form}
            size="large"
            onFinish={(e) => {
              let tmpData = {
                th_name: e.th_name,
                en_name: e.en_name,
                image: fileId,
              };
              addItem(tmpData, (err, res) => {
                if (err) {
                  notification["error"]({
                    message: res.error.statusCode || res.status,
                    description: err,
                  });
                }
                if (res.status == "error" || res.status == "fail") {
                  notification["error"]({
                    message: res.error.statusCode || res.status,
                    description: res.message,
                  });
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  res = res.data;
                  form.resetFields();
                  props.onSumit(res);
                  message.success("This is a success add Item");
                  props.oncancel();
                }
              });
            }}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={6}
                xxl={4}
                style={{ textAlign: "center" }}
              >
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    {...dataDragger}
                    maxCount={1}
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}
                  >
                    {t("form.upload")}
                  </Upload>
                </ImgCrop>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={9}
                xxl={10}
                style={{ padding: "0 20px 0 20px" }}
              >
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#4F4F4F",
                      }}
                    >
                      {t("form.th_name")}
                    </div>
                  }
                  autoComplete="off"
                  name="th_name"
                  rules={[
                    {
                      required: true,
                      message: `${t("message.inputitemnamethai")}`,
                    },
                    {
                      pattern: "[ก-ฮ]",
                      message: `${t("message.inputthaicharacters")}`,
                    },
                  ]}
                >
                  <Input
                    showCount
                    className="inputbox"
                    placeholder={t("form.th_name")}
                    maxLength={30}
                    style={{ borderRadius: "8px" }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={9}
                xxl={10}
                style={{ padding: "0 20px 0 20px" }}
              >
                <Form.Item
                  label={
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#4F4F4F",
                      }}
                    >
                      {t("form.en_name")}
                    </div>
                  }
                  autoComplete="off"
                  name="en_name"
                  rules={[
                    {
                      required: true,
                      message: `${t("message.inputitemnameeng")}`,
                    },
                    {
                      pattern: "[a-zA-Z]",
                      message: `${t("message.inputengcharacters")}`,
                    },
                  ]}
                >
                  <Input
                    showCount
                    className="inputbox"
                    placeholder={t("form.en_name")}
                    maxLength={30}
                    style={{ borderRadius: "8px" }}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={4} style={{ margin: '0 25px 0 0' }}>
            <Form.Item
              label={
                <div
                  style={{
                    fontWeight: '500',
                    fontSize: '16px',
                    color: '#4F4F4F'
                  }}
                >
                  Timer
                </div>
              }
              autoComplete='off'
              name='timer'
              rules={[
                {
                  required: true,
                  message: 'Please input your Name!'
                }
              ]}
            >
              <TimePicker
                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                style={{ borderRadius: '8px' }}
                format={'mm:ss'}
              />
            </Form.Item>
          </Col> */}
            </Row>

            <Row justify="end" style={{ gap: "8px", margin: "0 20px 0 20px" }}>
              <Button
                htmlType="button"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  props.oncancel();
                }}
              >
                {t("button.cancel")}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("button.add")}{i18n.language == "th" ? "สิ่งของ" : "Item"}
              </Button>
            </Row>
          </Form>
        </div>
      </Col>
      <Col xs={24} sm={24} md={2} lg={4} xl={6} xxl={6}></Col>
    </Row>
  );
}

export default Additem;
