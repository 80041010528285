const fetchRoom = async cb => {
  let token = localStorage.getItem('token')
  token = JSON.parse(token)
  fetch(`${process.env.REACT_APP_API_URL_HOUSEKEEPER}api/housekeeper/rooms`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token.token}`,
      Accept: 'application/json'
    })
  })
    .then(res => res.json())
    .then(json => cb(null, json))
    .catch(err => cb(err, null))
}

export default fetchRoom
