import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import language_th from './locales/th/common.json'
import language_en from './locales/en/common.json'

var language = localStorage.getItem('language')
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: language_en
      },
      th: {
        translation: language_th
      }
    },
    lng: language || 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'ten',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
export default i18n
