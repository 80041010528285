import { createSlice } from '@reduxjs/toolkit'
export const meetingroomSlice = createSlice({
  name: 'meetingroom',
  initialState: { dataRoom: [], dataUser: {} },
  reducers: {
    editdataRoom: (state, action) => {
      let tmp = action.payload
      tmp = tmp.map(e => {
        return {
          _id: e.id,
          label: e.project,
          displayName: e.displayname,
          capacity: e.capacity,
          building: e.building,
          emailAddress: e.emailAddress,
          floorLabel: e.floorLabel,
          floorNumber: e.floorNumber
        }
      })
      state.dataRoom = tmp
    },
    editdataUser: (state, action) => {
      let tmp = action.payload
      state.dataUser = tmp
    }
  }
})

export const { editdataRoom, editdataUser } = meetingroomSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getMeetingroom = state => state.meetingRoom.dataRoom
export const getMydata = state => state.meetingRoom.dataUser
export default meetingroomSlice.reducer
