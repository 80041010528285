import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Tooltip,
  Button,
  Avatar,
  Skeleton,
  message,
  notification,
  Row,
  Col,
} from "antd";
import {
  DisconnectOutlined,
  EditFilled,
  DeleteFilled,
  KeyOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import getAllHouseKeeper from "../../api/housekeeper/getAllHouseKeeper";
import disableUser from "../../api/housekeeper/disableHousekeeper";
import dateRole from "../../api/role.json";

import Editmodal from "./modaleditHouseKeeper";
import ResetpasswordHouseKeeper from "./resetpasswordHouseKeeper";
import Deletemodal from "./modaldeleteHouseKeeper";

const phoneFormat = (input) => {
  if (!input || isNaN(input)) return `input must be a number was sent ${input}`;
  if (typeof input !== "string") input = input.toString();
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (input.length < 10) {
    return "was not supplied enough numbers please pass a 10 digit number";
  } else if (input.length > 10) {
    return "was supplied too many numbers please pass a 10 digit number";
  } else {
    return "something went wrong";
  }
};
export default function TableHouseKeeper(props) {
  const { t, i18n } = useTranslation();
  const [isFetch, setIsfetch] = useState(false);
  const [datas, setDatas] = useState([]);
  const [datasFilter, setDatasFilter] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tmpDataid, setTmpdatid] = useState({});
  const [totalPage, setTotalfrom] = useState("");
  const [pageFisrt, setPageFisrt] = useState(1);
  const [pageLast, setPageLast] = useState(1);
  const columns = [
    {
      title: t("headdertable.housekeeper.image"),
      dataIndex: "image",
      key: "image",
      width: "10%",
      render: (text) => (
        <Avatar
          size="large"
          src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${text}`}
        />
      ),
    },
    {
      title: t("headdertable.housekeeper.name"),
      dataIndex: `${i18n.language}_name`,
      key: `${i18n.language}_name`,
      sorter: (a, b) =>
        a[`${i18n.language}_name`].localeCompare(b[`${i18n.language}_name`]),
      render: (text) => text,
    },
    {
      title: t("headdertable.housekeeper.username"),
      dataIndex: `username`,
      key: `username`,
      render: (text) => text,
    },
    {
      title: t("headdertable.housekeeper.role"),
      key: "role",
      dataIndex: "role",
      sorter: (a, b) =>
        a.role[`${i18n.language}_name`].localeCompare(
          b.role[`${i18n.language}_name`]
        ),
      render(text, record) {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {text[`${i18n.language}_name`]}
          </div>
        );
      },
    },
    {
      title: t("headdertable.housekeeper.phone"),
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      render: (text) => phoneFormat(text),
    },
    {
      title: t("headdertable.housekeeper.action"),
      key: "action",
      render: (_, record) => {
        if (props.profile.HousekeeperRole.id == 1) {
          return (
            <Space>
              <Tooltip
                title={
                  !record.available ? t("button.link") : t("button.unlink")
                }
                placement="bottom"
              >
                <Button
                  type={"primary"}
                  ghost={record.available}
                  icon={<DisconnectOutlined />}
                  size={"middle"}
                  style={{ borderRadius: "8px", sboxShadow: "unset",
                  textShadow: "unset", }}
                  onClick={() => {
                    disableUser(record.id, (err, res) => {
                      if (
                        !err &&
                        res.statusCode >= 200 &&
                        res.statusCode < 300
                      ) {
                        const updatedArray = datasFilter.map((user) =>
                          user.id == record.id
                            ? {
                                ...user,
                                available: !record.available,
                              }
                            : user
                        );
                        const updatedArrayreal = datas.map((user) =>
                          user.id == record.id
                            ? {
                                ...user,
                                available: !record.available,
                              }
                            : user
                        );
                        setDatas(updatedArrayreal);
                        setDatasFilter(updatedArray);
                      } else {
                        notification["error"]({
                          message: res.error.statusCode || res.status,
                          description: res.message,
                        });
                      }
                    });
                  }}
                />
              </Tooltip>
              <Tooltip title="Reset Password" placement="bottom">
                <Button
                  type="primary"
                  ghost
                  icon={<KeyOutlined />}
                  size={"middle"}
                  style={{ borderRadius: "8px", boxShadow: "unset",
                  textShadow: "unset", }}
                  onClick={() => {
                    setTmpdatid({
                      id: record.id,
                      th_name: record.th_name,
                      en_name: record.en_name,
                    });
                    setResetModal(true);
                    props.onClosebuttonAdd();
                  }}
                />
              </Tooltip>
              <Tooltip title={t("button.edit")} placement="bottom">
                <Button
                  type="primary"
                  icon={<EditFilled />}
                  size={"middle"}
                  style={{ borderRadius: "8px", boxShadow: "unset",
                  textShadow: "unset", }}
                  onClick={() => {
                    setTmpdatid({
                      id: record.id,
                      th_name: "none",
                      en_name: "none",
                    });
                    props.onClosebuttonAdd();
                    setEditModal(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={t("button.delete")} placement="bottom">
                <Button
                  type="primary"
                  icon={<DeleteFilled />}
                  size={"middle"}
                  danger
                  style={{ borderRadius: "8px", boxShadow: "unset",
                  textShadow: "unset", }}
                  onClick={() => {
                    setTmpdatid({
                      id: record.id,
                      th_name: record.th_name,
                      en_name: record.en_name,
                    });
                    props.onClosebuttonAdd();
                    setDeleteModal(true);
                  }}
                />
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];
  useEffect(() => {
    if (!isFetch) {
      getAllHouseKeeper((err, res) => {
        if (err) {
          message.error("Bad Gateway: " + err);
        }
        if (res && res.statusCode === 200) {
          res = res.data;
          if (res.filter((d) => d.role.id > 1 && d.role.id < 5).length > 10) {
            setTotalfrom("1-10");
          } else {
            setTotalfrom(
              "1-" + res.filter((d) => d.role.id !== 1 && d.role.id < 5).length
            );
          }
          setDatas(res.filter((d) => d.role.id !== 1 && d.role.id < 5));
          setDatasFilter(res.filter((d) => d.role.id !== 1 && d.role.id < 5));
          setIsfetch(true);
          props.onFetch(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    const currValue = props.strFilter;
    var dataformat = datasFilter.map((d) => {
      return {
        ...d,
        phoneNumber_name: d.phoneNumber,
        role_th_name: d.role.th_name,
        role_en_name: d.role.en_name,
      };
    });

    const filteredData = dataformat.filter((entry) =>
      Object.keys(entry)
        .filter((k) => k.includes("name"))
        .some((k) => {
          // if (currValue.includes('admin')) {
          //   return String(entry[k])
          //     .toLowerCase()
          //     .includes(1)
          // } else if (currValue.includes('staff')) {
          //   return String(entry[k])
          //     .toLowerCase()
          //     .includes(2)
          // } else if (currValue.includes('housekeeper')) {
          //   return String(entry[k])
          //     .toLowerCase()
          //     .includes(3)
          // } else {
          return String(entry[k])
            .toLowerCase()
            .includes(currValue.toLowerCase());
          // }
        })
    );
    if (filteredData.length > 10) {
      if (pageFisrt == 1) {
        setTotalfrom(1 + "-" + 10);
      } else {
        setTotalfrom(
          `${10 * (pageFisrt - 1) + 1} - ${
            10 * pageFisrt > filteredData.length
              ? filteredData.length
              : 10 * pageFisrt
          }`
        );
      }
    } else if (filteredData.length < 10 && filteredData.length !== 0) {
      setTotalfrom(1 + "-" + filteredData.length);
    } else {
      setTotalfrom("");
    }
    setDatas(filteredData);
  }, [props.strFilter]);
  useEffect(() => {
    if (_.isObject(props.tmpData) && !_.isEmpty(props.tmpData)) {
      let tmp = {
        ...props.tmpData,
        key: datas.length + 1,
        role: props.tmpData.HousekeeperRole,
      };
      setDatas([...datasFilter, tmp]);
      setDatasFilter([...datasFilter, tmp]);
      if (pageFisrt !== 1) {
        setTotalfrom(`${10 * (pageFisrt - 1) + 1} -  ${pageLast + 1} `);
        setPageLast(pageLast + 1);
      } else if (datasFilter.length < 10) {
        setPageFisrt(1);
        setTotalfrom("1-" + (datasFilter.length + 1));
      } else {
        setPageFisrt(1);
        setTotalfrom("1-10");
      }
      props.SETtmpData();
    }
  }, [props.tmpData]);
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={20} xl={22} xxl={22}>
        <div style={{ marginTop: "20px", width: "100%" }}>
          {!isFetch && <Skeleton active />}
          {isFetch && (
            <>
              <div style={{ float: "right" }}>
                {totalPage} {`${t("form.from")}`} {datas.length}
              </div>
              <Table
                columns={columns}
                dataSource={datas}
                pagination={{
                  onChange: (page, pageSize) => {
                    setTotalfrom(
                      `${pageSize * (page - 1) + 1} - ${
                        pageSize * page > datas.length
                          ? datas.length
                          : pageSize * page
                      }`
                    );
                    setPageFisrt(page);
                    setPageLast(
                      pageSize * page > datas.length
                        ? datas.length
                        : pageSize * page
                    );
                  },
                  defaultPageSize: 10,
                  showSizeChanger: false,
                  pageSizeOptions: ["10"],
                }}
              />
            </>
          )}

          <Editmodal
            modalOpen={editModal}
            housekeeper={tmpDataid}
            onClose={() => {
              setTmpdatid({});
              setEditModal(false);
            }}
            handleUpdated={(data) => {
              const updatedArray = datasFilter.map((user) =>
                user.id === data.id
                  ? {
                      ...user,
                      email: data.email,
                      en_name: data.en_name,
                      en_surname: data.en_surname,
                      id: data.id,
                      image: data.image,
                      phoneNumber: data.phoneNumber,
                      th_name: data.th_name,
                      th_surname: data.th_surname,
                      username: data.username,
                      role: data.HousekeeperRole,
                    }
                  : user
              );
              const updatedArrayreal = datas.map((user) =>
                user.id === data.id
                  ? {
                      ...user,
                      email: data.email,
                      en_name: data.en_name,
                      en_surname: data.en_surname,
                      id: data.id,
                      image: data.image,
                      phoneNumber: data.phoneNumber,
                      th_name: data.th_name,
                      th_surname: data.th_surname,
                      username: data.username,
                      role: data.HousekeeperRole,
                    }
                  : user
              );
              setDatas(updatedArrayreal);
              setDatasFilter(updatedArray);
              setEditModal(false);
              setTmpdatid({});
              // find array
            }}
            typeList={dateRole.data}
          />
          <Deletemodal
            modalOpen={deleteModal}
            housekeeper={tmpDataid}
            onClose={() => {
              setTmpdatid({});
              setDeleteModal(false);
            }}
            handleUpdated={() => {
              const updatedArray = datasFilter.filter(
                (user) => user.id !== tmpDataid.id
              );
              const updatedArrayreal = datas.filter(
                (user) => user.id !== tmpDataid.id
              );
              if (updatedArray.length > 10 && 10 * (pageFisrt - 1) + 1 !== 1) {
                setTotalfrom(`${10 * (pageFisrt - 1) + 1} -  ${pageLast - 1} `);
                setPageLast(pageLast - 1);
              } else if (updatedArray.length < 10) {
                setPageFisrt(1);
                setTotalfrom("1-" + updatedArray.length);
              } else {
                setPageFisrt(1);
                setTotalfrom("1-10");
              }
              setDatas(updatedArrayreal);
              setDatasFilter(updatedArray);
              setTmpdatid({});
              setDeleteModal(false);
            }}
          />
          <ResetpasswordHouseKeeper
            modalOpen={resetModal}
            housekeeper={tmpDataid}
            onClose={() => {
              setTmpdatid({});
              setResetModal(false);
            }}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={4} xl={2} xxl={2}></Col>
    </Row>
  );
}
