import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getMeetingroom } from "../../slice/roomSlice";

import Nodata from "../nodata";
import TooltipOrder from "./TooltipOrder";

import { fetchOrderbyroom } from "../../api/order/getAllOrder";

import moment from "moment";
import _ from "lodash";
export default function Monitor(props) {
  const Meetingroom = useSelector(getMeetingroom);
  const { i18n } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState({});
  const [isFetch, setIsfetch] = useState(false);

  useEffect(() => {
    if (!isFetch && !_.isEmpty(Meetingroom)) {
      let startDate = moment().startOf("day").toISOString();
      let endDate = moment().toISOString();
      fetchOrderbyroom(
        null,
        Meetingroom.map((r) => r._id),
        startDate,
        endDate,
        (err, res) => {
          if (err) {
            notification["error"]({
              message: res.error.statusCode || res.status,
              description: err,
            });
          }
          if (res && res.statusCode === 200) {
            res = res.data;
            let order_all = res;
            const data = Meetingroom.map((r) => {
              return {
                ...r,
                Order: order_all
                  .filter((order) => order.roomId == r._id)
                  .filter((order) => order.statusId <= 4),
              };
            }).sort(function (x, y) {
              return +y.Order.length - +x.Order.length;
            });
            setDatas(data);
            setIsfetch(true);
          }
        }
      );
    }
  }, [Meetingroom]);

  useEffect(() => {
    if (!_.isEmpty(props.mqtt_client)) {
      if (!isConnected) {
        props.mqtt_client.subscribe(`dtcampus/housekeeper/room/#`, {}, (err, g) => {
          if (err) {
            console.log("err", err);
          }
          // console.log(`dtcampus/housekeeper/room/${props.room_id}/order/#`);
          setIsConnected(true);
        });
      }
    }
    props.mqtt_client.on("message", function (topic, message, packet) {
      try {
        let msgJSON = JSON.parse(message.toString());
        if (topic.indexOf(`dtcampus/housekeeper/room/`) >= 0) {
          setLastMessage(msgJSON);
        }
      } catch (error) {}
    });
    return () => {
      if (!_.isEmpty(props.mqtt_client)) {
        props.mqtt_client.unsubscribe(`dtcampus/housekeeper/room/#`);
      }
    };
  }, []);
  useEffect(() => {
    if (isConnected) {
      var msg = lastMessage;
      var result = datas.find((ed) => msg.roomId == ed._id);
      if (!_.isEmpty(result)) {
        var order = result.Order.find((o) => o.id == msg.id);
        if (msg.statusId === 1 && _.isEmpty(order)) {
          //Create
          result.Order.push(msg);
          const createEventArray = datas
            .map((ed) =>
              ed._id === msg.roomId
                ? {
                    ...result,
                  }
                : ed
            )
            .sort(function (x, y) {
              return +y.Order.length - +x.Order.length;
            });
          setDatas(createEventArray);
        } else if (msg.statusId === 4 && !_.isEmpty(order)) {
          //Update
          const tmpUpdatedEventArray = result.Order.map((order) =>
            order.id === msg.id
              ? {
                  ...msg,
                  Room: order.Room,
                }
              : order
          );
          const updatedEventArray = datas
            .map((ed) =>
              ed._id === msg.roomId
                ? {
                    ...ed,
                    Order: tmpUpdatedEventArray,
                  }
                : ed
            )
            .sort(function (x, y) {
              return +y.Order.length - +x.Order.length;
            });
          setDatas(updatedEventArray);
        } else if (msg.statusId >= 6 && !_.isEmpty(order)) {
          const tmpUpdatedEventArray = result.Order.filter(
            (order) => order.id !== msg.id
          );
          const updatedEventArray = datas
            .map((ed) =>
              ed._id === msg.roomId
                ? {
                    ...ed,
                    Order: tmpUpdatedEventArray,
                  }
                : ed
            )
            .sort(function (x, y) {
              return +y.Order.length - +x.Order.length;
            });
          setDatas(updatedEventArray);
        }
      }
    }
  }, [lastMessage]);

  if (isConnected) {
    return (
      <>
        {!isFetch && <Skeleton active />}
        {isFetch && !_.isEmpty(datas.filter((r) => r.Order.length > 0)) && (
          <div style={{ backgroundColor: "#fff", padding: "10px 0" }}>
            <Row gutter={[16, 16]}>
              {datas
                .filter((r) => r.Order.length > 0)
                .map((e, i) => {
                  return (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      xxl={12}
                      key={i}
                    >
                      <div
                        style={{
                          backgroundColor: "rgba(242, 242, 242, 0.5)",
                          minHeight: "150px",
                          borderRadius: "8px",
                          padding: "20px",
                          // width:'60%'
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#4F4F4F",
                            fontWeight: 500,
                            lineHeight: '18px',
                            marginBottom:'18px'
                          }}
                        >
                          {e.displayName}
                        </h3>
                        <TooltipOrder key={e._id} data={e.Order} />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        )}
        {isFetch && _.isEmpty(datas.filter((r) => r.Order.length > 0)) && (
          <Nodata
            message={i18n.language == "th" ? "ยังไม่มีออเดอร์" : "No order yet"}
          />
        )}
      </>
    );
  } else {
    return <Spin />;
  }
}
