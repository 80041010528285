import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

export default function TooltipItem (props) {
  const { t, i18n } = useTranslation()
  const [items, setItems] = useState([])

  useEffect(() => {
    if (!_.isEmpty(props.datas)) {
      setItems(props.datas)
    }
  }, [props])
  return (
    <div style={{ padding: '28px 16px', borderRadius: '8px' }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <div
          style={{
            color: '#4F4F4F',
            fontSize: '24px',
            lineHeight: '18px',
            fontWeight: 500
          }}
        >
          <h3>{t('headers.item')}</h3>
        </div>
      </Row>

      <div
        style={{
          minHeight: '40px',
          maxHeight: '165px',
          overflowX: 'hidden',
          margin: '4px 0px'
        }}
      >
        {items.map((pd, index) => {
          return (
            <>
              <Row style={{ margin: '14px 0' }} key={index + '_pd'}>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <img
                    src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${pd.image}`}
                    alt=''
                    style={{
                      padding: '0',
                      width: '40px',
                      height: '40px'
                    }}
                  />
                </Col>
                <Col
                  xs={15}
                  sm={15}
                  md={15}
                  lg={15}
                  xl={15}
                  xxl={15}
                  style={{ alignSelf: 'center' }}
                >
                  <div
                    style={{
                      fontWeight: '400',
                      fontSize: '16px',
                      color: '#4F4F4F',
                      lineHeight: '18px'
                      // padding: "0 12px 0 12px",
                    }}
                  >
                    {pd[`${i18n.language}_name`]}
                  </div>
                </Col>
              </Row>
            </>
          )
        })}
      </div>
    </div>
  )
}
