import React, { useEffect, useState } from 'react'

import {
  Select,
  Form,
  Button,
  Row,
  Col,
  InputNumber,
  Modal,
  Alert,
  message,
  notification
} from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { useSelector } from 'react-redux'
import { getMeetingroom } from '../../slice/roomSlice'

import fetchEventsCheckin from '../../api/room/getEventCheckkin'
import getItembyRoomId from '../../api/room/getItembyRoomId'
import CreateOrder from '../../api/order/addOrder'

export default function ModaladdOrder (prop) {
  const { t, i18n } = useTranslation()
  const Meetingroom = useSelector(getMeetingroom)
  const [modalOpen, setModalOpen] = useState(false)
  const [optionsArea, setOptionsarea] = useState([])
  const [itemData, setItemData] = useState([])
  const [form] = Form.useForm()
  const [eventData, setEventdata] = useState({})
  const [isfetch, setIsfetch] = useState(false)

  useEffect(() => {
    setModalOpen(prop.modalOpen)
    if (!isfetch) {
      setOptionsarea(
        Meetingroom.map(r => {
          return { label: r.displayName, value: r._id }
        })
      )
    }
  }, [prop])

  return (
    <>
      <Modal
        title={`${t('headers.create', {
          name: `${t('menu.order')}`
        })}`}
        visible={modalOpen}
        footer={null}
        onCancel={() => {
          prop.onClose()
          setOptionsarea([])
          setIsfetch(false)
          setEventdata({})
          setItemData([])
          form.resetFields()
        }}
        style={{ padding: '0 30px' }}
        width={555}
      >
        <Form
          autoComplete='off'
          form={form}
          name='control-hooks'
          layout='vertical'
          //   style={{ padding: '0 30px 20px 30px' }}
          onFinish={e => {
            let order_array = itemData.map(iD => {
              return {
                id: iD.id,
                piece: iD.piece || 0
              }
            })
            if (order_array.reduce((accumulator,currentValue)=>  currentValue.piece + accumulator,0) == 0) {
              notification['error']({
                message: t('message.order_zero'),
                description: 'Not has order'
              })
              return;
            }
            let tmp = {
              ordertype: 'meeting',
              orders: order_array,
              description: ''
            }
            CreateOrder(eventData.id, tmp, (err, res) => {
              if (res && res.statusCode === 200) {
                message.success('This is a success Create Order')
                prop.onClose()
                setOptionsarea([])
                setIsfetch(false)
                setEventdata({})
                setItemData([])
                form.resetFields()
              } else if (res.status == 'error' || res.status == 'fail') {
                notification['error']({
                  message: res.error.statusCode || res.status,
                  description: res.message
                })
              }
            })
          }}
        >
          <Form.Item
            label={
              <div
                style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  color: '#4F4F4F'
                }}
              >
                {t('form.room')}
              </div>
            }
            name='room'
            rules={[
              {
                required: true,
                message: `${t("message.inputroom")}`,
              }
            ]}
          >
            <Select
              placeholder={`${t('form.selectroom')}`}
              showSearch
              options={optionsArea}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className='ant-select-selector'
              onChange={async e => {
                const event = await fetchEventsCheckin(e)
                setItemData([])
                if (event.statusCode == 200) {
                  let response = event.data
                  if (_.isEmpty(response)) {
                    setEventdata({
                      message: 'No event checkin',
                      subject: ''
                    })
                    setIsfetch(false)
                    setItemData([])
                  } else {
                    setEventdata(response)
                    getItembyRoomId(e, (err, res) => {
                      if (err) {
                        notification['error']({
                          message: res.error.statusCode || res.status,
                          description: err
                        })
                      }
                      if (res && res.statusCode == 200) {
                        res = res.data.filter(entry=> entry.enabledisplay)
                        setItemData(res)
                        setIsfetch(true)
                      }
                    })
                  }
                } else {
                  notification['error']({
                    message: event.error.statusCode || event.status,
                    description: event.message
                  })
                }
              }}
            ></Select>
          </Form.Item>
          {!_.isEmpty(eventData) && (
            <Alert
              message={eventData.message || eventData.subject}
              type='info'
              // style={{height: '50px'}}
            />
          )}

          {isfetch && (
            <>
              <div
                style={{
                  padding: '0px 13px 0px 13px',
                  marginTop: '10px',
                  overflowX: 'hidden',
                  height: '280px'
                }}
              >
                {itemData.map((item, i) => {
                  return (
                    <>
                      <Row
                        style={{
                          margin: '0 0 25px 0',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Col span={14} style={{ textAlign: 'start' }}>
                          <Row>
                            <Col span={7}>
                              <img
                                src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${item.image}`}
                                alt=''
                                style={{
                                  padding: '0',
                                  width: '30px',
                                  height: '30px'
                                }}
                              />
                            </Col>
                            <Col span={17} style={{ alignSelf: 'center' }}>
                              <div
                                style={{
                                  fontWeight: 'normal',
                                  fontSize: '16px',
                                  color: '#828282'
                                }}
                              >
                                {item[`${i18n.language}_name`]}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={10} style={{ textAlign: 'end' }}>
                          <Form.Item style={{ margin: '0' }}>
                            <InputNumber
                              min={0}
                              max={50}
                              style={{ borderRadius: '8px' }}
                              defaultValue={0}
                              onChange={e => {
                                const array = [...itemData]
                                array[i] = { ...array[i], piece: e }
                                setItemData(array)
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )
                })}
              </div>
              <div
                style={{ padding: '0px 23px 0px 23px', margin: '24px 0 0 0' }}
              >
                <div
                  style={{
                    borderBottom: '1.5px solid #BDBDBD',
                    width: '100%',
                    margin: '0 0 22px 0'
                  }}
                ></div>
                <Row style={{ justifyContent: 'center', padding: '0' }}>
                  <Button
                    htmlType='button'
                    ghost
                    style={{
                      boxShadow: "unset",
                      textShadow: "unset",
                      borderRadius: '8px',
                      fontSize: '14px',
                      margin: '0 0 0px 10px',
                      background: '#BDBDBD',
                      border: '1px solid #BDBDBD',
                      color: '#FFFFFF',
                      fontWeight: '500'
                    }}
                    onClick={() => {
                      prop.onClose()
                      setOptionsarea([])
                      setIsfetch(false)
                      setEventdata({})
                      setItemData([])
                      form.resetFields()
                    }}
                  >
                    {t('button.cancel')}
                  </Button>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{
                      boxShadow: "unset",
                      textShadow: "unset",
                      borderRadius: '8px',
                      fontSize: '14px',
                      margin: '0 0 0px 10px',
                      fontWeight: '500'
                    }}
                  >
                    {t('button.create')}{t('menu.order')}
                  </Button>
                </Row>
              </div>
            </>
          )}
        </Form>
      </Modal>
    </>
  )
}
