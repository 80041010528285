import React, { useState } from "react";
import "./addHouseKeeper.css";
import {
  Form,
  Upload,
  Row,
  Col,
  Input,
  Select,
  Button,
  message,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import _ from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import addHouseKeeper from "../../api/housekeeper/addHouseKeeper";
import dateRole from "../../api/role.json";

export default function AddHouseKeeper(props) {
  const [fileId, setFileid] = useState([]);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const dataDragger = {
    name: "filename",
    multiple: false,
    // action: `${process.env.REACT_APP_API_URL}item`,
    headers: {
      // Authorization: `Bearer ${token.payload.token}`,
    },
    beforeUpload(file) {
      setFileid(file);
      return false;
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // setFileid(response.data.item.id);
      } else if (status === "error") {
        // Notify("error", "Upload Failed", "Server error , " + response.message);
        message.error(`File upload failed.  ${response.message}`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={22} lg={20} xl={20} xxl={20}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#e3e1e1",
            margin: "20px",
            padding: "10px",
            borderRadius: "8px",
            animation: " fadeInDown 0.5s",
            transition: "fadeOutUp 0.5s",
          }}
        >
          <Form
            autoComplete="off"
            form={form}
            layout="vertical"
            style={{ padding: "20px 24px 10px 24px" }}
            onFinish={(e) => {
              addHouseKeeper(
                {
                  email: e.email,
                  th_name: e.th_name,
                  th_surname: e.th_surname,
                  en_name: e.en_name,
                  en_surname: e.en_surname,
                  phoneNumber: e.phone,
                  role: e.role,
                  username: e.username,
                  password: e.password,
                  image: fileId,
                },
                (err, res) => {
                  if (err) {
                    notification["error"]({
                      message: "User error",
                      description: err,
                    });
                  }
                  if (res.status == "error" || res.status == "fail") {
                    notification["error"]({
                      message: res.error.statusCode || res.status,
                      description: res.message,
                    });
                  }
                  if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    form.resetFields();
                    props.onSumit(res.data);
                    message.success("This is a success add housekeeper");
                    props.oncancel();
                  }
                }
              );
            }}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                xxl={4}
                style={{ textAlign: "center" }}
              >
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    {...dataDragger}
                    maxCount={1}
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}
                  >
                    {t("form.upload")}
                  </Upload>
                </ImgCrop>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.th_name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputnamethai")}`,
                        },
                        {
                          pattern: "[ก-ฮ]",
                          message: `${t("message.inputthaicharacters")}`,
                        },
                      ]}
                      name="th_name"
                    >
                      <Input
                        placeholder={t("form.th_name")}
                        style={{ borderRadius: "8px" }}
                        // showCount
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.th_surname")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputsurnamethai")}`,
                        },
                        {
                          pattern: "[ก-ฮ]",
                          message: `${t("message.inputthaicharacters")}`,
                        },
                      ]}
                      name="th_surname"
                    >
                      <Input
                        placeholder={t("form.th_surname")}
                        style={{ borderRadius: "8px" }}
                        // showCount
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.email")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputemail")}`,
                        },
                        {
                          type: "email",
                          message: `${t("message.inputtypeemail")}`,
                        },
                      ]}
                      name="email"
                    >
                      <Input
                        placeholder={t("form.email")}
                        style={{ borderRadius: "8px" }}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.en_name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputnameeng")}`,
                        },
                        {
                          pattern: "[a-zA-Z]",
                          message: `${t("message.inputengcharacters")}`,
                        },
                      ]}
                      name="en_name"
                    >
                      <Input
                        placeholder={t("form.en_name")}
                        style={{ borderRadius: "8px" }}
                        // showCount
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.en_surname")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputsurnameeng")}`,
                        },
                        {
                          pattern: "[a-zA-Z]",
                          message: `${t("message.inputengcharacters")}`,
                        },
                      ]}
                      name="en_surname"
                    >
                      <Input
                        placeholder={t("form.en_surname")}
                        style={{ borderRadius: "8px" }}
                        // showCount
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <label
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                            margin: "0 8px 0 0",
                          }}
                        >
                          {t("form.phone")}
                        </label>
                      }
                      name={"phone"}
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputphone")}`,
                        },
                        {
                          min: 10,
                          message: `${t("message.phonecharacters")}`,
                        },
                        {
                          pattern: "[0-9]{3}[0-9]{3}[0-9]{4}",
                          message: `${t("message.inputformatphone")}`,
                        },
                      ]}
                    >
                      <Input
                        style={{ paddingRight: "23.5px", borderRadius: "8px" }}
                        placeholder={t("form.phone")}
                        showCount
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.username")}
                        </div>
                      }
                      tooltip={{
                        title: `${t("message.pattern_username")}`,
                        icon: <InfoCircleOutlined />,
                      }}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputusername")}`,
                        },
                        {
                          min: 8,
                          message: `${t("message.usernamecharacters")}`,
                        },
                        {
                          pattern: /^[a-zA-Z0-9]{8,25}$/,
                          message: ``,
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("form.username")}
                        style={{ borderRadius: "8px" }}
                        showCount
                        maxLength={15}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.password")}
                        </div>
                      }
                      tooltip={{
                        title: `${t("message.pattern_password")}`,
                        icon: <InfoCircleOutlined />,
                      }}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputpassword")}`,
                        },
                        {
                          min: 8,
                          message: `${t("message.passwordcharacters")}`,
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                          message: ``,
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={t("form.password")}
                        style={{ borderRadius: "8px" }}
                        showCount
                        maxLength={25}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    style={{ padding: "0 25.57px" }}
                  >
                    <Form.Item
                      label={
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#4F4F4F",
                          }}
                        >
                          {t("form.role")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("message.inputrole")}`,
                        },
                      ]}
                      name="role"
                    >
                      <Select
                        style={{
                          width: "100%",
                          color: "#828282",
                          borderRadius: "8px !important",
                        }}
                        // className='ant-select-selector'
                        options={dateRole.data.filter((e) => e.value !== 1)}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              justify="end"
              style={{ padding: "0 25.57px", margin: "5px", gap: "8px" }}
            >
              <Button
                htmlType="button"
                onClick={() => {
                  props.oncancel();
                }}
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("button.cancel")}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("button.add")}
                {t("menu.housekeeper")}
              </Button>
            </Row>
          </Form>
        </div>
      </Col>
      <Col xs={24} sm={24} md={2} lg={4} xl={4} xxl={4}></Col>
    </Row>
  );
}
