import React, { useState } from 'react'
import { Row, Col, Layout, Typography, Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import { SearchOutlined } from '@ant-design/icons'

import TableOrder from '../../components/order/tableOrder'
import AddFormdata from '../../components/order/addOrder'

export default function Order (props) {
  const { t, i18n } = useTranslation()
  const [addButton, setAddbutton] = useState(false)
  const [isfetch, setIsfetch] = useState(false)
  const [strFilter, setStrfilter] = useState('')
  var { Content } = Layout
  const { Title } = Typography
  return (
    <div
      style={{ padding: '20px', margin: '5px 5px 15px 20px' }}
    >
      <Content style={{ padding: '0' }}>
        <Title
          style={{
            color: 'var(--ant-primary-color)',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '24px'
            // margin: '0 0 30px 0'
          }}
        >
          {i18n.language === 'th' ? 'ใบสั่งวันนี้' : 'Today Order'}
        </Title>
        <Row>
          <Col
            span={16}
            style={{
              border: '1px solid #E0E0E0',
              padding: '20px',
              height: '740px',
              overflowX: 'hidden',
              margin: '0 20px 20px 0'
            }}
          >
            <TableOrder
              strFilter={strFilter}
              onFetch={f => {
                setIsfetch(f)
              }}
            />
          </Col>
          <Col span={6}>
            <Button
              loading={!isfetch}
              htmlType='button'
              onClick={() => {
                setAddbutton(!addButton)
              }}
              type='primary'
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: '500',
                padding: '10',
                margin: '0 0 20px 0'
              }}
            >
              {t('button.create')}{t('menu.order')}
            </Button>
            {/* <Col span={6}> */}
            <Row style={{ justifyContent: 'flex-start' }}>
              <Input
                suffix={
                  <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                }
                className='ant-inputsearch search-border'
                placeholder={`${t('button.search')}${t('menu.meetingroom')}`}
                style={{
                  // margin: '0px 0px 0px 10px',
                  borderRadius: '8px',
                  border: '1px solid var(--ant-primary-color)',
                  maxWidth: '200px'
                }}
                onChange={e => {
                  const currValue = e.target.value
                  setStrfilter(currValue)
                }}
              />
            </Row>
            {/* </Col> */}
          </Col>
        </Row>
      </Content>
      {addButton && (
        <AddFormdata
          modalOpen={addButton}
          onClose={() => {
            setAddbutton(!addButton)
          }}
        />
      )}
    </div>
  )
}
