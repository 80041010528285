import React, { useEffect, useState } from 'react'
import { Row, Col, notification, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import Editmodal from './modaleditgroupItem'
import Deletemodal from './modaldeletegroupItem'

import dateRole from '../../api/role.json'
import fetchGroupItem from '../../api/groupitem/getAllGroupItem'

export default function TableGroupitem(props) {
  const { t, i18n } = useTranslation()
  const [isFetch, setIsfetch] = useState(false)
  const [datas, setDatas] = useState([])
  const [datasFilter, setDatasFilter] = useState([])
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [tmpDataid, setTmpdatid] = useState({})

  useEffect(() => {
    if (!isFetch) {
      fetchGroupItem((err, res) => {
        if (err) {
          notification['error']({
            message: res.error.statusCode || res.status,
            description: err
          })
        }
        if (res && res.statusCode === 200) {
          res = res.data
          setDatas(res)
          setDatasFilter(res)
          setIsfetch(true)
          props.onFetch(true)
        }
      })
    }
  }, [])
  useEffect(() => {
    const currValue = props.strFilter
    var dataformat = datasFilter.map((e) => {
      return {
        ...e,
        item_en_name: e.items.map((t) => t.en_name),
        item_th_name: e.items.map((t) => t.th_name),
        room_name: e.rooms.map((t) => t.name),
      }
    })

    const filteredData = dataformat.filter(entry =>
      Object.keys(entry).filter((k) => k.includes("name")).some(k => {
        return String(entry[k])
          .toLowerCase()
          .includes(currValue.toLowerCase())
      })
    )
    setDatas(filteredData)
  }, [props.strFilter])
  useEffect(() => {
    if (_.isObject(props.tmpData) && !_.isEmpty(props.tmpData)) {
      let tmp = {
        ...props.tmpData,
        key: datas.length + 1,
        rooms: props.tmpData.Rooms,
        items: props.tmpData.Items
      }
      setDatas([...datasFilter, tmp])
      setDatasFilter([...datasFilter, tmp])
    }
  }, [props.tmpData])
  return (
    <div
      style={{
        marginTop: '30px',
        width: '70%',
        overflow: 'overlay',
        height: '600px'
      }}
    >
      {!isFetch && <Skeleton active />}
      {isFetch && (
        <>
          {_.isEmpty(datas) ? (
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <svg
                className='ant-empty-img-simple'
                width='64'
                height='41'
                viewBox='0 0 64 41'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g transform='translate(0 1)' fill='none' fillRule='evenodd'>
                  <ellipse
                    className='ant-empty-img-simple-ellipse'
                    cx='32'
                    cy='33'
                    rx='32'
                    ry='7'
                  ></ellipse>
                  <g className='ant-empty-img-simple-g' fillRule='nonzero'>
                    <path d='M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z'></path>
                    <path
                      d='M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z'
                      className='ant-empty-img-simple-path'
                    ></path>
                  </g>
                </g>
              </svg>
              <div style={{ color: "#00000040", fontSize: "14px", lineHeight: "1.5715" }}>No Data</div>
            </div>
          ) : (
            <div>
              {datas.map(G => {
                return (
                  <Row style={{ margin: '0 0 20px 0px' }} key={G.key}>
                    <Col
                      span={22}
                      style={{
                        background: '#F2F2F2',
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        padding: '30px 40px 30px 34.37px'
                      }}
                    >
                      <Row style={{ alignItems: 'center' }}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={18} style={{marginBottom:'16px'}}>
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: '24px',
                              color: 'var(--ant-primary-color)'
                            }}
                          >
                            {G[`${i18n.language}_name`]}
                          </div>
                          <Row>
                            <div
                              style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                color: '#828282',
                                margin: '0px 5px 0px 0px '
                              }}
                            >
                              {t('menu.meetingroom')} :
                            </div>
                            <div
                              style={{
                                color: '#BDBDBD',
                                fontWeight: '400',
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                                width: '50%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textTransform: 'capitalize'
                              }}
                            >
                              {G.rooms.map(r => r.name).join(' , ')}
                            </div>
                          </Row>
                          <Row>
                            <div
                              style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                color: '#828282',
                                margin: '0px 5px 0px 0px '
                              }}
                            >
                              {t('menu.item')} :
                            </div>

                            <div
                              style={{
                                color: '#BDBDBD',
                                fontWeight: '400',
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                width: '50%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {G.items.filter(item => item.enabledisplay)
                                .length == 0 ? (
                                <>
                                  {i18n.language == 'th'
                                    ? 'ไม่พบสิ่งของ'
                                    : ' No Items'}
                                </>
                              ) : (
                                G.items
                                  .filter(item => item.enabledisplay)
                                  .map(d => d[`${i18n.language}_name`] + ',')
                              )}
                            </div>
                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={5} xxl={3} style={{marginBottom:'4px'}}>
                          <Row
                            style={{
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setEditModal(true)
                              setTmpdatid({
                                id: G.id,
                                th_name: 'none',
                                en_name: 'none'
                              })
                            }}
                          >
                            {props.profile.HousekeeperRole.id == 1 && (
                              <>
                                <svg
                                  width='18'
                                  height='19'
                                  viewBox='0 0 18 19'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M3.32352 14.8551C3.36816 14.8551 3.41281 14.8507 3.45745 14.844L7.21191 14.1855C7.25656 14.1765 7.29897 14.1565 7.33022 14.123L16.7923 4.66092C16.813 4.64027 16.8294 4.61574 16.8406 4.58873C16.8518 4.56173 16.8575 4.53278 16.8575 4.50355C16.8575 4.47432 16.8518 4.44537 16.8406 4.41837C16.8294 4.39136 16.813 4.36683 16.7923 4.34618L13.0825 0.634129C13.04 0.591718 12.9842 0.569397 12.924 0.569397C12.8637 0.569397 12.8079 0.591718 12.7655 0.634129L3.30343 10.0962C3.26995 10.1297 3.24986 10.1698 3.24093 10.2145L2.58245 13.969C2.56074 14.0885 2.56849 14.2116 2.60505 14.3275C2.64162 14.4434 2.70587 14.5487 2.79227 14.6341C2.93959 14.777 3.12486 14.8551 3.32352 14.8551ZM4.82799 10.9623L12.924 2.8685L14.5601 4.50467L6.46415 12.5984L4.47977 12.9489L4.82799 10.9623ZM17.2141 16.7301H0.785575C0.390485 16.7301 0.0712891 17.0493 0.0712891 17.4444V18.248C0.0712891 18.3462 0.151646 18.4265 0.24986 18.4265H17.7499C17.8481 18.4265 17.9284 18.3462 17.9284 18.248V17.4444C17.9284 17.0493 17.6092 16.7301 17.2141 16.7301Z'
                                    fill='var(--ant-primary-color)'
                                  />
                                </svg>

                                <div
                                  style={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    padding: '0px 11px',

                                    color: '#828282'
                                  }}
                                >
                                  {t('button.edit')}
                                </div>
                              </>
                              )}

                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={5} xxl={3} style={{marginBottom:'4px'}}>
                          <Row
                            style={{
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setDeleteModal(true)
                              setTmpdatid({
                                id: G.id,
                                th_name: G.th_name,
                                en_name: G.en_name
                              })
                              setTmpdatid(G)
                            }}
                          >
                            {props.profile.HousekeeperRole.id == 1 && (
                              <>
                                <svg
                                  width='18'
                                  height='19'
                                  viewBox='0 0 18 19'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M5.6068 2.17654H5.42822C5.52644 2.17654 5.6068 2.09618 5.6068 1.99797V2.17654H12.3925V1.99797C12.3925 2.09618 12.4729 2.17654 12.5711 2.17654H12.3925V3.78368H13.9997V1.99797C13.9997 1.21002 13.359 0.569397 12.5711 0.569397H5.42822C4.64028 0.569397 3.99965 1.21002 3.99965 1.99797V3.78368H5.6068V2.17654ZM16.8568 3.78368H1.14251C0.747419 3.78368 0.428223 4.10288 0.428223 4.49797V5.21226C0.428223 5.31047 0.50858 5.39083 0.606794 5.39083H1.95501L2.50635 17.0649C2.54206 17.8261 3.17153 18.4265 3.93269 18.4265H14.0666C14.83 18.4265 15.4572 17.8283 15.493 17.0649L16.0443 5.39083H17.3925C17.4907 5.39083 17.5711 5.31047 17.5711 5.21226V4.49797C17.5711 4.10288 17.2519 3.78368 16.8568 3.78368ZM13.8947 16.8194H4.10456L3.56438 5.39083H14.4349L13.8947 16.8194Z'
                                    fill='var(--ant-primary-color)'
                                  />
                                </svg>

                                <div
                                  style={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    padding: '0px 11px',
                                    color: '#828282'
                                  }}
                                >
                                  {t('button.delete')}
                                </div>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              })}
            </div>
          )}
        </>
      )}

      <Editmodal
        modalOpen={editModal}
        groupitem={tmpDataid}
        onClose={() => {
          setTmpdatid({})
          setEditModal(false)
        }}
        handleUpdated={data => {
          const updatedArray = datasFilter.map(groupitem =>
            groupitem.id === data.id
              ? {
                ...groupitem,
                en_name: data.en_name,
                id: data.id,
                th_name: data.th_name,
                rooms: data.Rooms,
                items: data.Items
              }
              : groupitem
          )
          const updatedArrayreal = datas.map(groupitem =>
            groupitem.id === data.id
              ? {
                ...groupitem,
                en_name: data.en_name,
                id: data.id,
                th_name: data.th_name,
                rooms: data.Rooms,
                items: data.Items
              }
              : groupitem
          )
          setDatas(updatedArrayreal)
          setDatasFilter(updatedArray)
          setEditModal(false)
          setTmpdatid({})
        }}
        typeList={dateRole.data}
      />
      <Deletemodal
        modalOpen={deleteModal}
        groupitem={tmpDataid}
        onClose={() => {
          setTmpdatid({})
          setDeleteModal(false)
        }}
        handleUpdated={() => {
          const updatedArray = datasFilter.filter(
            user => user.id !== tmpDataid.id
          )
          const updatedArrayreal = datas.filter(
            user => user.id !== tmpDataid.id
          )
          setDatas(updatedArrayreal)
          setDatasFilter(updatedArray)
          setDeleteModal(false)
          setTmpdatid({})
        }}
      />
    </div>
  )
}
