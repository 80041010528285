import React, { useEffect, useState } from "react";
import { Row, Col, notification, Tooltip, Tag, Spin } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import moment from "moment";

import { fetchOrderbyroom } from "../../api/order/getAllOrder";

import DetailOrder from "./detailorder";

export default function TooltipOrder(props) {
  const { t, i18n } = useTranslation();

  const [datas, setDatas] = useState([]);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (props.data) {
      setDatas(props.data);
    }
  }, [props]);
  return (
    <>
      {datas
        .filter((e) => e.statusId >= 1 && e.statusId <= 4)
        .reverse()
        .map((order) => {
          return (
            <Tooltip
              placement="right"
              title={<DetailOrder order={order} />}
              color="white"
              overlayInnerStyle={{
                padding: 0,
                borderRadius: "8px",
                width: "350px",
              }}
            >
              <Row
                justify={"space-around"}
                style={{ margin: "10px 0", cursor: "pointer" }}
              >
                <Col
                  span={12}
                  style={{
                    fontSize: "16px",
                    color: "#4F4F4F",
                    fontWeight: 500,
                    lineHeight: "16px",
                    marginBottom: "18px",
                    opacity: '0.75'
                  }}
                >
                  {t("menu.order")} {order.id}
                </Col>
                <Col span={12}>
                  <Tag
                    icon={<ClockCircleOutlined />}
                    style={{ fontSize: "14px", width: "90px" }}
                    color={order.statusId == 1 ? "blue" : "green"}
                  >
                    {order.Status[`${i18n.language}_name`]}
                  </Tag>
                </Col>
              </Row>
            </Tooltip>
          );
        })}
    </>
  );
}
