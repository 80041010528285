import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Progress } from "antd";

import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import ItemCount from "../../api/overview/Itemcount";
export default function Itemcount(props) {
  const { i18n } = useTranslation();

  const [datas, setDatas] = useState([1]);

  const [isFetch, setIsfetch] = useState(false);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (!isFetch && props.Radiovalue) {
      let sDate = moment().startOf("day").toISOString();
      let eDate = moment().endOf("day").toISOString();
      ItemCount(sDate, eDate, (err, result) => {
        if (!err && result.statusCode === 200) {
          let count = result.data
            .map((d) => d.sales)
            .reduce((a, b) => a + b, 0);
          setTotal(count);
          setDatas(result.data);
          setIsfetch(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (isFetch && props.Radiovalue) {
      var sDate;
      var eDate;
      if (props.Radiovalue == "d") {
        sDate = moment().startOf("day").toISOString();
        eDate = moment().endOf("day").toISOString();
      } else if (props.Radiovalue == "w") {
        sDate = moment().startOf("week").toISOString();
        eDate = moment().endOf("week").toISOString();
      } else if (props.Radiovalue == "m") {
        sDate = moment().startOf("month").toISOString();
        eDate = moment().endOf("month").toISOString();
      }

      ItemCount(sDate, eDate, (err, result) => {
        if (!err && result.statusCode === 200) {
          let count = result.data
            .map((d) => d.sales)
            .reduce((a, b) => a + b, 0);
          setTotal(count);
          setDatas(result.data);
        }
      });
    }
  }, [props]);

  return (
    <div
      style={{
        border: "1px solid #E0E0E0",
        background: "#FFFFFF",
        // height: '400px',
        width: "100%",
        overflowX: "scroll",
        height: "500px",
      }}
    >
      {!isFetch && <Skeleton active style={{ margin: "20px" }} />}
      {isFetch && (
        <div style={{ maxHeight: "80%", margin: "20px" }}>
          {datas.map((item) => {
            return (
              <Row
                justify="space-around"
                align="middle"
                style={{ marginBottom: "10px", padding: "10px" }}
              >
                <Col span={4}>
                  <img
                    src={`${process.env.REACT_APP_API_URL_HOUSEKEEPER}images/housekeeper/${item.image}`}
                    alt=""
                    style={{
                      // padding: '0',
                      width: "30px",
                      height: "30px",
                      alignSelf: "center",
                    }}
                  />
                </Col>
                <Col span={6}>
                  <div style={{ alignSelf: "center" }}>
                    {item[`${i18n.language}_name`]}
                  </div>
                </Col>
                <Col span={10}>
                  <Progress
                    percent={(item.sales / total) * 100}
                    showInfo={false}
                  />
                </Col>
                <Col span={4}>
                  <div style={{ float: "right" }}>{item.sales}</div>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
}
